import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { motion } from "framer-motion";
interface Faq {
  question: string;
  answer: string;
}

interface Props {
  faqs: Faq[];
  faqs2: Faq[];
}

const faqs = [
  {
    question: "Najam",
    answer: "Najam automobila",
    answer2: "Najam skutera",
    answer3: "Najam bicikli",
    answer4: "Najam brodova",
  },
];

const faqs2 = [
  {
    question: "Osiguranje",
    answer: "Osiguranje",
    answer2: "Putno Osiguranje",
  }
]


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeIndex2, setActiveIndex2] = useState<number | null>(null);

  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const handleClick2 = (index: number) => {
    setActiveIndex2(activeIndex2 === index ? null : index);
  };

  return (
    <nav className="container mx-auto text-black px-4 pt-5 flex items-center justify-between">
      <div className="logo">
        <a href="/hr">
          <img src={logo} alt="Atlas Vela Luka Touristic Agency Logo" />
        </a>
      </div>
      <ul className="as flex links items-center gap-5 text-xl text-black">
        <li>
          <a href={"/hr"}>Naslovna</a>
        </li>
        <div className="dropdown">
          <button className="dropbtn">Najam</button>
          <div className="dropdown-content">
            <a href={"/hr/najam-auta"}>Najam Automobila</a>
            <a href={"/hr/najam-skutera"}>Najam Skutera</a>
            <a href={"/hr/najam-brodova"}>Najam Brodova</a>
            <a href={"/hr/najam-bicikli"}>Najam Bicikli</a>
          </div>
        </div>
        <li>
          <a href={"/hr/karte"}>Karte</a>
        </li>
        <div className="dropdown">
          <button className="dropbtn">Osiguranje</button>
          <div className="dropdown-content">
            <a href={"/hr/osiguranje/"}>Osiguranje</a>
            <a href={"/hr/osiguranje/putno-osiguranje"}>Putno Osiguranje</a>
          </div>
        </div>
        <li>
          <a href={"/hr/putovanja"}>Ekskurzije</a>
        </li>
        <li>
          <a href={"/hr/smjestaj"}>Smještaj</a>
        </li>
        <li>
          <a href={"/hr/nekretnine"}>Nekretnine</a>
        </li>
        <li>
          <a href={"/hr/ribarska-dozvola"}>Ribarske dozvole</a>
        </li>
        <li className="bg-oceanBlue py-3 px-7 rounded-full">
          <a className="text-white" href={"/"}>
            EN
          </a>
        </li>
        <li className="bg-oceanBlue py-3 px-7 rounded-full">
          <a className="text-white" href={"/hr/kontakt"}>
            Kontakt
          </a>
        </li>
      </ul>
      <div className="overlay" style={{width: isOpen ? "100%" : "0%"}}>
        <ul>
          <li>
            <a href={"/hr"}>Naslovna</a>
          </li>
          <li>
            <a href={"/hr/karte"}>Karte</a>
          </li>
          {faqs2.map((faq, index) => (
              <div className="text-center text-xl" key={faq.question}>
                <motion.h3
                    className={`question ${
                        activeIndex2 === index ? "active" : ""
                    }`}
                    onClick={() => handleClick2(index)}
                    animate={{
                      transition: { duration: 0.3, ease: "easeInOut" },
                    }}
                >
                  <li className="flex gap-3">{faq.question}</li>
                </motion.h3>
                <motion.div
                    className={`answer ${
                        activeIndex2 === index ? "active" : ""
                    }`}
                    initial={{ maxHeight: 0, opacity: 0 }}
                    animate={{
                      maxHeight: activeIndex2 === index ? 1000 : 0,
                      opacity: activeIndex2 === index ? 1 : 0,
                      transition: { duration: 0.3, ease: "easeInOut" },
                    }}
                >
                  <li>
                    <a href="/hr/osiguranje">{faq.answer}</a>
                  </li>
                  <li>
                    <a href="/hr/osiguranje/putno-osiguranje">{faq.answer2}</a>
                  </li>
                </motion.div>
              </div>
          ))}
          <li className={"z-50"}>
          <a href={"/hr/putovanja"}>Ekskurzije</a>
          </li>
          {faqs.map((faq, index) => (
            <div className="text-center text-xl" key={faq.question}>
              <motion.h3
                className={`question ${activeIndex === index ? "active" : ""}`}
                onClick={() => handleClick(index)}
                animate={{
                  transition: { duration: 0.3, ease: "easeInOut" },
                }}
              >
                <li className="flex gap-3">{faq.question}</li>
              </motion.h3>
              <motion.div
                className={`answer ${activeIndex === index ? "active" : ""}`}
                initial={{ maxHeight: 0, opacity: 0 }}
                animate={{
                  maxHeight: activeIndex === index ? 1000 : 0,
                  opacity: activeIndex === index ? 1 : 0,
                  transition: { duration: 0.3, ease: "easeInOut" },
                }}
              >
                <li>
                  <a href="/hr/najam-auta">{faq.answer}</a>
                </li>
                <li>
                  <a href={"/hr/najam-skutera"}>{faq.answer2}</a>
                </li>
                <li>
                  <a href={"/hr/najam-bicikli"}>{faq.answer3}</a>
                </li>
                <li>
                  <a href={"/hr/najam-brodova"}>{faq.answer4}</a>
                </li>
              </motion.div>
            </div>
          ))}

          <li className="z-50">
            <a href={"/hr/smjestaj"}>Smještaj</a>
          </li>
          <li className="z-50">
            <a href={"/hr/nekretnine"}>Nekretnine</a>
          </li>
          <li className="z-50">
            <a href={"/hr/ribarska-dozvola"}>Ribarske dozvole</a>
          </li>
          <li className="bg-oceanBlue py-3 px-7 rounded-full">
            <a className="text-white" href={"/"}>
              EN
            </a>
          </li>
          <li className="bg-oceanBlue py-3 px-7 rounded-full">
            <a className="text-white" href={"/hr/kontakt"}>
              Kontakt
            </a>
          </li>
        </ul>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <svg
          className={
            isOpen ? "ham hamRotate180 ham5 active" : "ham hamRotate180 ham5"
          }
          viewBox="0 0 100 100"
          width="80"
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429"
          />
          <path className="line middle" d="m 70,50 h -40" />
          <path
            className="line bottom"
            d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429"
          />
        </svg>
      </div>
    </nav>
  );
};

export default Navbar;
