import rentImage from "../../assets/img/vela.jpg";
const PriceBoat = () => {
  return (
    <section className="price container mx-auto px-4">
      <h2 className="main-title py-4">Uključeno u cijenu</h2>
      <div className="price-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
        <div className="price-selection">
          <ul className="gap-5 flex flex-col">
            <li>Osiguranje putnika</li>
            <li>Vesla</li>
            <li>Nadstrešnica</li>
            <li>Sidro</li>
            <li>Prva pomoć</li>
            <li>Prsluci</li>
          </ul>
        </div>
        <img className="rounded-xl" src={rentImage} alt="" />
      </div>
    </section>
  );
};

export default PriceBoat;
