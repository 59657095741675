import car from "../../assets/img/car-small.png";
import chevy2 from "../../assets/img/chevy2.webp";
import chevy3 from "../../assets/img/chevy3.webp";
import toyota from "../../assets/img/aygo.png";
import suzuki from "../../assets/img/suzuki.png";
import up from "../../assets/img/cutt.png";

interface Cars {
  id: number;
  title: string;
  spec: string;
  image: {
    src: any;
    alt: string;
  };
  stars: number;
}

const cars: Cars[] = [
  {
    id: 1,
    title: "Chevrolet Lacetti",
    spec: "Eurosuper 95 AC Car radio Airbag 5 doors",
    image: {
      src: car,
      alt: "Chevrolet Lacetti - rental car in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 2,
    title: "Suzuki Baleno Automatic",
    spec: "Eurosuper 95 AC Car radio, Bluetooth, Airbag 5 doors",
    image: {
      src: suzuki,
      alt: "Suzuki Baleno Automatic - rental car in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 6,
    title: "VW UP",
    spec: "Eurosuper 95 AC Car radio Airbag 5 doors",
    image: {
      src: up,
      alt: "VW UP - rental car in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 3,
    title: "Chevrolet Aveo 1.2",
    spec: "Eurosuper 95 AC Car radio Airbag 5 doors",
    image: {
      src: chevy3,
      alt: "Chevrolet Lacetti - rental car in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 3,
    title: "Toyota Aygo",
    spec: "Eurosuper 95 AC Car radio Airbag 5 doors",
    image: {
      src: toyota,
      alt: "Toyota Aygo - rental car in Vela Luka Korčula",
    },
    stars: 3,
  },
];

export default cars;
