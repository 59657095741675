import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KriloLogo from "../../assets/img/krilo-logo.png";
function createData(name: string, mondayHours: string, utorakHours: string) {
  return {
    name,
    mondayHours,
    utorakHours,
  };
}

function createDataThird(
  name: string,
  length: string,
  days: string,
  holidays: string
) {
  return { name, length, days, holidays };
}

const rows = [
  createData("8:00", "Split", "19:45"),
  createData("08:25-08:30", "Brač (Milna)", "19:15-19:20"),
  createData("09:00-09:10", "Hvar (Hvar)", "18:30-18:40"),
  createData("10:20-10:30", "Korčula (Old Town)", "17:00-17:10"),
  createData("11:05-11:10", "Mljet (Pomena)", "16:20-16:25"),
  createData("12:35", "Dubrovnik", "15:00"),
];

const rows2 = [
  createData("7:30", "Split", "20:25"),
  createData("07:55-08:00", "Brač (Milna)", "19:50-19:55"),
  createData("08:30-08:40", "Hvar (Hvar)", "19:10-19:20"),
  createData("09:50-10:00", "Korčula (Old Town)", "17:55-18:05"),
  createData("10:30-10:35", "Mljet (Pomena)", "17:15-17:20"),
  createData("11:55", "Dubrovnik", "16:00"),
];

const rows3 = [
  createData("8:45", "Split", "21:10"),
  createData("09:35-09:45", "Brač (Bol)", "20:00-20:10"),
  createData("10:20-10:30", "Makarska", "19:15-19:25"),
  createData("11:40-11:50", "Korčula (Old Town", "17:50-18:00"),
  createData("12:20-12:30", "Pomena (Mljet)", "17:00-17:10"),
  createData("14:00", "Dubrovnik", "15:05"),
];

const rows4 = [
  createData("10:00", "Split", "15:35"),
  createData("11:00-11:15", "Hvar", "14:25-14:35"),
  createData("12:30", "Korčula (Old Town)", "13:00"),
];

const rows5 = [
  createData("17:00", "Split", "08:45"),
  createData("17:55-18:10", "Hvar", "07:35-07:50"),
  createData("19:00-19:05", "Prigradica", "06:40-06:45"),
  createData("19:45", "Korčula", "06:00"),
];
const Krilotable = () => {
  return (
    <section className="timetable container mx-auto px-4">
      <img src={KriloLogo} alt="Krilo" className="py-10" />
      <h2 className="arriva-title py-5">Krilo - Kapetan Luka</h2>
      <h4>Split - Brač - Hvar - Korčula - Mljet - Dubrovnik</h4>
      <TableContainer component={Paper} className="mb-10">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Every day</TableCell>
              <TableCell>01.04. – 31.10.2024.</TableCell>
              <TableCell>Every day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.mondayHours}</TableCell>
                <TableCell>{row.utorakHours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h4 className="py-10">
        Split - Brač - Makarska - Korčula - Mljet - Mljet - Dubrovnik
      </h4>
      <TableContainer component={Paper} className="mb-10">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Every day</TableCell>
              <TableCell>10.06. – 22.09.2024.</TableCell>
              <TableCell>Every day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows3.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.mondayHours}</TableCell>
                <TableCell>{row.utorakHours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <h4 className="py-4">Split - Hvar - Korčula</h4>
      <TableContainer component={Paper} className="mb-10">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Every day</TableCell>
              <TableCell>31.05. - 29.09.</TableCell>
              <TableCell>Every day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows4.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.mondayHours}</TableCell>
                <TableCell>{row.utorakHours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <h4 className="py-10">Split – Hvar – Prigradica – Korčula</h4>
      <TableContainer component={Paper} className="mb-10">
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Every day</TableCell>
              <TableCell>02.06. - 01.10.2023.</TableCell>
              <TableCell>Every day</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows5.map((row) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell>{row.mondayHours}</TableCell>
                <TableCell>{row.utorakHours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <a
        className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
        href="/contact"
      >
        Buy Ticket
      </a>
    </section>
  );
};

export default Krilotable;
