import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Location from "../components/insurance/Location";
import Why from "../components/insurance/Why";
import Navbar from "../components/rents/Navbar";
import crsig from "../assets/img/crsig-logo.svg";
import Offers from "../components/insurance/Offers";
import Contact from "../components/rents/Contact";

import boat from "../assets/img/boat-sec.jpg";

const option = [
  {
    option: "Home insurance",
    value: "Home insurance",
  },
  {
    option: "Travel insurance",
    value: "Travel insurance",
  },
  {
    option: "Insurance question",
    value: "Insurance question",
  },
];

const HrInsurance = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  return (
    <>
      {showButton && (
        <img
          className="fixed right-5 bottom-40"
          src={crsig}
          alt="Croatia Osiguranje Partners logo"
        />
      )}
      <Navbar />
      <Offers />
      <Why />
      <div className="container mx-auto px-4 pb-5">
        <h2 className="main-title py-5">
          Zašto ugovoriti obvezno osiguranje plovila i jahti u Croatia
          Osiguranje?
        </h2>
        <img src={boat} className="rounded-xl" alt="" />
        <p className="py-5 text-xl">
          Zašto bi Croatia Osiguranje obvezno osiguranje plovila i
          jahte?
        </p>
        <p>
          Obvezno osiguranje plovila pokriva odgovornost vlasnika ili korisnika
          čamac ili jahta za naknadu štete uslijed smrti, tjelesne
          ozljede, oštećenja zdravlja plivača, ronioca ili ljudi na kopnu.
          Vlasnici plovila dužni su svake godine osigurati plovilo, te
          osiguranje se ugovara na godinu dana. Što je pokriveno? Odgovornost za
          štetu koju prouzročite drugima, u slučaju da nekoga ubijete, ozlijedite ili ozlijedite
          svojim plovilom Osigurana svota propisana je zakonom i iznosima
          na 464.529,83 € (3.500.000,00 HRK) po događaju. Dodatne mogućnosti
          Dobrovoljno osiguranje od nezgode Osiguranje osobe koja upravlja vozilom
          plovila i putnika od posljedica nesreće.
        </p>
      </div>
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Contact option={option} />
        <Location />
      </div>
      <Footer />
    </>
  );
};

export default HrInsurance;
