import fish from "../../assets/img/fish.webp";
import modra from "../../assets/img/modra.webp";
import stiniva from "../../assets/img/uvala.webp";
import prodzid from "../../assets/img/prodzid.webp";
import bigGame from "../../assets/img/bigame.jpg";
import hvar from "../../assets/img/hvar.jpg";
import game from "../../assets/img/game.jpg";
const Trips = () => {
  return (
    <>
      <section className="container mx-auto px-4 offer-insurance">
        <h1 className="bigTitle">
          Istražite prekrasne plaže i lokacije u Hrvatskoj u blizini Korčule.
        </h1>
        <div className="wrapper travel pt-20 grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">
              Modra špilja + otok Vis, uvala Stiniva
            </h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Početna točka: </span>Vela Luka ( Centar )
              </p>
              <p>
                <span>Polazak: </span>8:00 AM
              </p>
              <p>
                <span>Dolazak: </span>9:15 AM
              </p>
              <p>
                Po dolasku, presjedanje u manje čamce za odlazak do špilje
                razgledavanje. Slijedi povratak na brod i putovanje
                nastavlja prema otoku Visu - Komiži. Tokom putovanja,
                gosti će imati slobodno vrijeme u Komiži.
              </p>
              <p>
                Tijekom izleta predviđena je pauza za kupanje. Poznati
                Plaža Stiniva je najposjećenija, ali zbog gužve tijekom
                ljetnih mjeseci. Ručak nije uključen u cijenu (restorani
                u gradu Komiži uz dobre cijene i preporuku dobre hrane
                od strane skipera)
              </p>
              <p className="not-included">
                *U cijenu nije uključena ulaznica za Modru špilju.
                <br />
                <br />
                *Izlet se organizira u manjim grupama od 6-10 putnika (za
                za veće grupe možemo organizirati izlet s dva broda
              </p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upoznajte nas
            </a>
          </div>
          <img
            className="mx-auto rounded-full"
            src={modra}
            alt="Modra špilja + otok Vis vacation trip"
          />
        </div>
        {/* <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <img
            className="mx-auto rounded-full"
            src={uvala}
            alt="Uvala stiniva vacation trip"
          />
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Uvala Stiniva</h3>
            <p>
              Insure yourself against health care costs during your stay in
              abroad for 45 days.
            </p>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Vela Luka ( City Center )
              </p>
              <p>
                <span>Starting time: </span>According to agreement
              </p>
              <p>
                It is located on the southern side of the island of Vis,
                bordered by high cliffs that protect a beautiful pebble beach.
                It is an idyllic place for swimming in the crystal clear sea
                that will remain in your memory forever. Due to the natural
                shade, it is also suitable for families with small children.
              </p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/contact"
            >
              Meet with us
            </a>
          </div>
        </div> */}
        <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <div>
            {/* <img
              className="mx-auto rounded-full"
              src={bigGame}
              alt="Uvala stiniva vacation trip"
            /> */}
            <img
              className="rounded-full text-center "
              src={game}
              alt="Big game fishing"
            />
          </div>
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Veliki ribolov</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Početna točka: </span>Vela Luka sa Merry Fisher 700
                brodom
              </p>
              <p>
                <span>Polazak: </span>Putovanje počinje u ranim jutarnjim satima
                sati (6-7 ujutro)
              </p>
              <p>
                Big game fishing je vrsta sportskog ribolova na otvorenom moru, kao
                što dalje od zemlje. Karakterizira ga lov na velike
                ribe kao što su tuna i sabljarka. Za sve vas koji žudite
                adrenalina, naš Proizd tim organizira jednodnevni ribolov pod
                vodstvom kapetana Nikole Žuvele. Nikola je bio zaljubljen
                s morem od djetinjstva i ima dugogodišnje iskustvo u
                ribarstvo.
              </p>
              <p>
                Još uvijek relativno neiskorišteno južno i jugozapadno područje
                Korčulanski arhipelag i okolica Lastova, Sušca,
                Palagruže, Hvar, Šćedar, Biševa, Vis i ostali otoci, idealno
                su za ovu vrstu ribolova.
              </p>
              <p>
                Povratak je u kasnim poslijepodnevnim satima (oko 18 sati) ovisno o tome
                dogovor.
              </p>
              <p>Cijena na upit, ovisi o broju osoba.</p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upoznajte nas
            </a>
          </div>
        </div>
        <div className="wrapper travel pt-20 pb-20 grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Ribarski piknik</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Početna točka: </span>Vela Luka ( Centar )
              </p>
              <p>
                <span>Polazak: </span>Po dogovoru
              </p>
              <p>
                <span>Dolazak: </span>Raspored ovisi o vašim željama
                i zahtjevima
              </p>
              <p>
                <span>Cijena: </span>Cijena ovisi o broju osoba,
                i djeca imaju popust.
              </p>
              <p>
                Za vas organiziramo riblji piknik u tradicionalnoj kamenoj kući
                na Proizd. Proizd je otok udaljen 7 nautičkih milja od Vele Luke,
                na sjevernoj strani velolučkog zaljeva. Poznat je po svojim
                prekrasne šljunčane plaže i tirkizno more, a kao priznanje
                za nju je dobila nagradu za najljepšu Hrvaticu
                plaža 2007. (plaža Bili Boki na zapadnoj obali
                otočić). Cjelodnevni užitak ispunjavamo ručkom po želji
                željama, au slobodno vrijeme možete se kupati i obilaziti otok.
                Izlet je namijenjen svima, a plaža Batala u blizini
                kamena kuca pogodna za djecu.
              </p>
              <p>
                Prijevoz, vozač, ručak (riba ili meso) i piće su
                uključena cijena. Preporučamo ponijeti kupaći kostim, ručnik,
                krema za lice sunčanje i oprema za ronjenje.
              </p>
            </div>
            <div className="flex items-center gap-10 btnInsure">
              <a
                className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
                href="/hr/kontakt"
              >
                Upoznajte nas
              </a>
              <a
                className="border-2 border-oceanBlue py-3 px-7 mt-10 rounded-full text-oceanBlue"
                href="/hr/ribarska-dozvola"
              >
                Ribarska dozvola
              </a>
            </div>
          </div>
          <img
            className="mx-auto rounded-full"
            src={fish}
            alt="Fish Picnic + Fish Licenses"
          />
        </div>
        <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <img
            className="mx-auto rounded-full"
            src={hvar}
            alt="Hvar and Paklinski otoci"
          />
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Hvar + Paklinski otoci</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Početna točka: </span>Polazak iz Vele Luke (Korčula
                otok), točno mjesto sastanka po dogovoru
              </p>
              <p>
                <span>Polazak: </span>9h *nije fiksno
              </p>
              <p>
                <span>Dolazak: </span>17h *nije fiksno
              </p>
              <p>
                <span>Vrijeme vožnje: </span>50 min
              </p>
              <p>
                Provedite nezaboravan dan na otoku koji nosi titulu
                najsunčanije mjesto u Hrvatskoj, broji ukupno 2700 sunčanih
                sati godišnje - zaplovite s nama do otoka Hvara!
              </p>
              <p>
                Hvar nije poznat samo po ugodnoj klimi i kristalnom moru, već
                ali i zbog svoje bogate kulturno-povijesne baštine. Posjetiti
                najstarije sačuvano kazalište u Europi, osnovano 1612. godine, nalazi se
                u gradu Hvaru. Prošećite tržnicom gdje se
                katedrala svetog Stjepana i prekrasne palače i pub
                građevine iz 15., 16. i 17. st. (biskupska
                Palača, Paladini, Leroj, Loggia itd.). Prolazak kroz glavnu
                gradska vrata Porta di Datallo, stepenice će vas odvesti prema
                Hvarska kula Forteca ili kako je Hvarani zovu Španjola.
                Danas se u tvrđavi nalazi zbirka amfora i raznih
                druge umjetnine iz srednjeg vijeka. Uz to
                prekrasne kamene arhitekture, imat ćete i priliku
                uživati u panorami grada, hvarske luke i
                obližnji otoci Paklin.
              </p>
              <p>
                Otoci Paklin savršeno su odredište za opuštanje
                od gradske vreve, udaljen svega desetak minuta
                brod iz luke Hvar. Provedite dan istražujući Paklin
                Otoci, uživajte u tajnim uvalama, kupajte se u kristalno plavom moru i
                opustite se u miru i netaknutoj prirodi. Otoci Paklin su
                zasigurno najljepši dio Hvarske rivijere.
              </p>
              <p>Cijena na upit, ovisi o broju osoba.</p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upoznajte nas
            </a>
          </div>
        </div>
        <div className="px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
          <img
            className="rounded-xl h-full w-full"
            src={stiniva}
            alt="Bicycle rental"
          />
          <img
            className="rounded-xl h-full w-full"
            src={prodzid}
            alt="Bicycle rental"
          />
        </div>
      </section>
    </>
  );
};

export default Trips;
