import Navbar from "../components/rents/Navbar";
import manInsurance from "../../assets/img/tinsurance.webp";
import cancel from "../../assets/img/cancel.webp";
import otkaz from "../../assets/img/otkaz.png";
import service from "../../assets/img/icons/24blue.png"
import covid from "../../assets/img/icons/coronablue.png"
import checkamark from "../../assets/img/icons/checkmark.svg"
import planet from "../../assets/img/icons/planetworld.png"
import Contact from "../components/rents/Contact";
import Footer from "../components/Footer";

const option = [
    {
        option: "Odabir",
        value: "Odabir Putno Osiguranje",
    },
    {
        option: "Putno Osiguranje",
        value: "Putno Osiguranje",
    },
];
const HrTravelInsurance = () => {
    return (
        <>
            <section className={"travel-insurance-wrapper"}>
                <Navbar/>
                <div className={"px-4"}>
                    <div className="container mx-auto px-4 rounded-2xl travel-insurance">
                        <div>
                            <h1 className={"bigTitle text-white"}>Putno Osiguranje</h1>
                            <p> Osigurajte se od troškova zdravstvene zaštite tijekom
                                vaš boravak u inozemstvu do 45 dana.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className="container mx-auto px-4 what-included py-10">
                        <div className={"grid grid-cols-1 md:grid-cols-2 items-center"}>
                            <img className={"rounded-2xl w-full md:w-2/3"} src={manInsurance} alt="Man Insurance"/>
                            <div>
                                <h2 className={"main-title"}>Što je uključeno</h2>
                                <ul className={"list-disc py-4"}>
                                    <li>
                                        Medicinska njega i hitni medicinski troškovi
                                    </li>
                                    <li>
                                        Prijevoz vozilom hitne pomoći ili helikopterom do najbliže bolnice
                                    </li>
                                    <li>
                                        Prijevoz u vašu domovinu ako se ne možete vratiti kući kako je planirano zbog
                                        zdravstvenih razloga
                                    </li>
                                    <li>
                                        Troškovi bolničkog liječenja i prijevoza osiguranih osoba do svojih
                                        domovina
                                        zbog bolesti od Covid-19
                                    </li>
                                </ul>
                                <div className={"text-oceanBlue gap-4"}>
                                    <div className={"flex gap-5 py-1"}>
                                        <span>#bezstresa</span>
                                        <span>#sigurnost</span>
                                        <span>#avantura</span>
                                    </div>
                                    <div className={"flex gap-5 py-2"}>
                                        <span>#bezpanike</span>
                                        <span>#istraži</span>
                                        <span>#sigurnijiuznas</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 py-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        <div className={""}>
                            <img src={service} alt="Service icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>24/7 usluga telefonskog asistenta </h3>
                            <p>U slučaju zdravstvenih problema, uputit ćemo Vas u najbližu zdravstvenu ustanovu.</p>
                        </div>
                        <div className={""}>
                            <img src={covid} alt="Covid icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>Osiguranje od bolesti COVID-19</h3>
                            <p>Zbog bolesti izazvane Covid-19 troškovi bolničkog liječenja i troškovi
                                povratka u domovinu pokriveni su.
                            </p>
                        </div>
                        <div className={""}>
                            <img src={planet} alt="Planet icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>Sigurnost u cijelom svijetu</h3>
                            <p>Kamo god putovali, osiguranje vas štiti tijekom boravka u inozemstvu.</p>
                        </div>
                    </div>
                </div>
                <div
                    className={"container mx-auto px-4 py-20 text-center flex flex-col justify-self-center items-center gap-5"}>
                    <h1 className={"bigTitle"}>Osiguranje od otkaza <br/> putovanja </h1>
                    <p className={"text-[#a3a3a3]"}>U slučaju otkazivanja putovanja, <br/> povrat novca do 90%
                        od iznosa koji ste platili
                        turističkoj agenciji</p>
                    <img className={"rounded-2xl"} src={otkaz} alt="Otkaz putovanja"/>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className={"container mx-auto px-4 py-20"}>
                        <h2 className={"main-title"}>Zašto ugovoriti Croatia Osiguranje <br/>osiguranje od otkaza? </h2>
                        <p className={"text-[#a3a3a3]"}>Osiguranje od otkaza putovanja je osiguranje koje vas štiti od
                            financijskog gubitka u slučaju da ste
                            prisiljeni otkazati putovanje zbog bolesti, nesreće ili prirodne katastrofe.</p>
                        <div className={"flex gap-4 pt-10 items-center"}>
                            <img src={checkamark} alt="Checkmark icon"/>
                            <p className={"text-[#a3a3a3]"}>Dobijte povrat novca za neiskorištene dane, čak i ako
                                prekinete svoje putovanje
                                nakon što je već počelo.</p>
                        </div>
                    </div>
                </div>
                <div className={"my-10"}>
                    <div className="container mx-auto px-4 what-included py-10">
                        <div className={"grid grid-cols-1 md:grid-cols-2 items-center"}>
                            <div>
                                <h2 className={"main-title"}>Što je uključeno ?</h2>
                                <ul className={"list-disc py-4"}>
                                    <p>Do 90% plaćenog iznosa putovanja u slučaju otkazivanja putovanja zbog:</p>
                                    <li>
                                        bolesti (osiguranika, životnog partnera, djece, roditelja)
                                    </li>
                                    <li>
                                        nesretnog slučaja
                                    </li>
                                    <li>
                                        elementarne nepogode u mjestu boravišta osiguranika
                                    </li>
                                    <li>
                                        smrti (osiguranika, životnog partnera, djece, roditelja)
                                    </li>
                                    <li>
                                        vojne vježbe
                                    </li>
                                </ul>
                                <div className={"text-oceanBlue gap-4"}>
                                    <div className={"flex gap-5 py-1"}>
                                        <span>#bezstresa</span>
                                        <span>#sigurnost</span>
                                        <span>#avantura</span>
                                    </div>
                                    <div className={"flex gap-5 py-2"}>
                                        <span>#bezpanike</span>
                                        <span>#istraži</span>
                                        <span>#sigurnijiuznas</span>
                                    </div>
                                </div>
                            </div>
                            <img className={"rounded-2xl w-full md:w-2/3"} src={cancel} alt="Man Insurance"/>
                        </div>
                    </div>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className={"container mx-auto px-4 py-20"}>
                        <h2 className={"main-title"}>Korisna informacija</h2>
                        <div className={"gap grid-cols-1 lg:grid-cols-2 justify-center pt-5"}>
                            <div className={"py-5"}>
                                <h3 className={"third-title"}>Kada se može dogovoriti?</h3>
                                <p className={""}>Osiguranje se može ugovoriti unutar tri dana od sklapanja ugovora
                                    o putovanju Ili kupovine avio karte i plaćanja smještaja.</p>
                            </div>
                            <div className={"py-5"}>
                                <h3 className={"third-title"}>Kada je moguće otkazati putovanje?</h3>
                                <p className={""}>Putovanje se, uslijed nastupanja osiguranog slučaja, može
                                    otkazati prije polaska ili prekinuti za vrijeme putovanja. Prekinete li već započeto
                                    putovanje, ostvarujete pravo na 90% plaćenog iznosa za dane koje niste
                                    iskoristili.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 my-10">
                    <Contact option={option}/>
                </div>
                <Footer/>
            </section>
        </>
    )
}

export default HrTravelInsurance