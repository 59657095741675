import Footer from "../components/Footer";
import Boat from "../components/rents/Boat";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Contact from "../components/rents/Contact";
import PriceBoat from "../components/rents/PriceBoat";
const faqs = [
  {
    question: "What if I cancel my reservation?",
    answer:
      "In case of cancellation of a car or scooter rental reservation, we retain the advance payment paid to confirm the reservation.",
  },
  {
    question: "Is fuel included in price?",
    answer:
      "Fuel is not included in the price of the vehicle rental, and when returning, the vehicle must be returned with the same amount of fuel as it was picked up.",
  },
  {
    question: "Are skis or towing balloon included?",
    answer: "Skis or towing balloon, as desired",
  },
  {
    question: "What equipment is provided with the boat rental?",
    answer:
      "All offered boats for rent have all the necessary equipment for a safe and comfortable stay at sea.",
  },
  {
    question: "Does the boat come with insurance?",
    answer:
      "Yes, each boat comes with insurance, but with a franchise that depends on the type of boat. Franchise participation in damages reduces the renter's liability for damages. If the cost of the damage is less than the value of the franchise, the renter has to pay for the smaller amount.",
  },
  {
    question: "Is a deposit required when renting a boat?",
    answer:
      "Yes, a refundable deposit is required when renting a boat. The deposit can only be in cash and/or pre-authorized on a credit card. The deposit will be returned if the boat is returned undamaged. We do not offer deposit insurance.",
  },
  {
    question: "What is not covered by the insurance?",
    answer: `The following risks are not covered by the boat rental insurance:
    <br>
    - Risks for which the boat rental company has insured its property, and for which a deductible is not defined in the contract.
    <br>
    - Intentional damage.
    <br>
    - Damage caused by inadequate management, defective equipment, or because the insured vessel was not in conditions suitable for navigation.
    <br>
    - Damage caused by construction, production, or material damage.`,
  },
];
const option = [
  {
    option: "Fisherman BlueMax 550",
    value: "Fisherman BlueMax 550",
  },
  {
    option: "Nautica 500",
    value: "Nautica 500",
  },
  {
    option: "Molinari",
    value: "Molinari",
  },
  {
    option: "Sessa Key Largo",
    value: "Sessa Key Largo",
  },
];
const BoatRental = () => {
  return (
    <>
      <Navbar />
      <Boat />
      <PriceBoat />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default BoatRental;
