const Avio = () => {
  return (
    <section className="ticket-header airplane my-5 container mx-auto px-4 rounded-xl">
      <div className="avio-content">
        <h2 className="text-white avio-title">
          Airline tickets for all destinations.
        </h2>
        <p>
          We can find you a plane ticket departing from Zagreb, Split, Dubrovnik
          for all European and world destinations such as London, Paris, New
          York...
        </p>
        <a
          className="bg-oceanBlue py-3 px-7 mt-20 rounded-full text-white"
          href="/contact"
        >
          Buy Ticket
        </a>
      </div>
    </section>
  );
};

export default Avio;
