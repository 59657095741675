import hours from "../../assets/img/icons/hours24.png";
import carpool from "../../assets/img/icons/Carpool.png";
import coronavirus from "../../assets/img/icons/Coronavirus.png";
import chouse from "../../assets/img/icons/Country House.png";
import livingRoom from "../../assets/img/icons/Living Room.png";
import globe from "../../assets/img/icons/globe.png";

interface WhyProps {
  id: number;
  logo: {
    image: any;
    alt: String;
  };
  name: String;
  desc: String;
}

const data: WhyProps[] = [
  {
    id: 1,
    logo: {
      image: hours,
      alt: "24/7 service by our agents",
    },
    name: "24/7 servis",
    desc: "U slučaju zdravstvenih problema, uputit ćemo Vas u najbližu zdravstvenu ustanovu.",
  },
  {
    id: 2,
    logo: {
      image: coronavirus,
      alt: "Coronavirus",
    },
    name: "Covid 19 osiguranje",
    desc: "Zbog bolesti uzrokovane Covid-19 pokriveni su troškovi bolničkog liječenja i troškovi povratka u domovinu.",
  },
  {
    id: 3,
    logo: {
      image: globe,
      alt: "Osiguranje u cijelom svijetu",
    },
    name: "Osiguranje u cijelom svijetu",
    desc: "Kamo god putovali, osiguranje vas štiti tijekom boravka u inozemstvu.",
  },
  {
    id: 4,
    logo: {
      image: carpool,
      alt: "24/7 usluga naših agenata",
    },
    name: "24/7 service",
    desc: "Svi građevinski dijelovi kuće ili stana, Pločice i parketi, Instalacije kao što su bojleri, klima uređaji i solarni paneli, Pomoćni objekti, ograde, nadstrešnice, sjenice i bazeni",
  },
  {
    id: 5,
    logo: {
      image: chouse,
      alt: "construction part",
    },
    name: "Građevinski dio",
    desc: "Svi građevinski dijelovi kuće ili stana, Pločice i parketi, Instalacije kao što su bojleri, klima uređaji i solarni paneli, Pomoćni objekti, ograde, nadstrešnice, sjenice i bazeni",
  },
  {
    id: 6,
    logo: {
      image: livingRoom,
      alt: "Things in the household",
    },
    name: "Stvari u kućanstvu",
    desc: "Namještaj, tehnika, odjeća i obuća, novac, nakit i umjetnine, troškovi izrade osobnih dokumenata i dokumenata, zidne i podne obloge, rolete i stakla na prozorima i vratima",
  },
];

const Why = () => {
  return (
    <>
      <section className="why-us container mx-auto px-4 why">
        <h2 className="insuranceTitle text-center py-8">Zašto izabrati nas?</h2>
        <div className="why-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 py-5 px-4">
          {data.slice(0, 3).map((el: any, key: any) => (
            <div className="content" key={key}>
              <img src={el.logo.image} alt={el.logo.alt} />
              <h4 className="whyTitle">{el.name}</h4>
              <p>{el.desc}</p>
            </div>
          ))}
        </div>
        <div className="why-wrapper grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-12 py-5 px-4">
          {data.slice(3, 6).map((el: any, key: any) => (
            <div className="content" key={key}>
              <img src={el.logo.image} alt={el.logo.alt} />
              <h4 className="whyTitle">{el.name}</h4>
              <p>{el.desc}</p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Why;
