import travelInsurance from "../../assets/img/travel.png";
import homeInsurance from "../../assets/img/home1.png";
import brochure from "../../assets/img/brochure.pdf";
const Offers = () => {
  return (
    <>
      <section className="container mx-auto px-4 offer-insurance">
        <h1 className="bigTitle">
          Vaša putovanja i najmi bez <span className="text-oceanBlue">STRESA.</span>
          <br/>
        </h1>
        <div className="wrapper travel grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Putno osiguranje</h3>
            <p>
              Osigurajte se od troškova zdravstvene zaštite tijekom boravka u
              u inostranstvu 45 dana.
            </p>
            <div className="price-selection second">
              <ul className="gap-5 flex flex-col">
                <h4>Što je uključeno?</h4>
                <li>Troškovi zdravstvene zaštite i hitne medicinske pomoći.</li>
                <li>
                  Prijevoz vozilom hitne pomoći ili helikopterom do najbliže
                  bolnica.
                </li>
                <li>
                  Prijevoz do vaše domovine ako se ne možete vratiti kući
                  kako je planirano zbog zdravstvenih razloga.
                </li>
                <li>
                  Troškovi bolničkog liječenja i prijevoza osiguranika
                  osoba u domovinu zbog bolesti od Covid-19
                </li>
              </ul>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upoznajte nas
            </a>
          </div>
          <img
            className="mx-auto"
            src={travelInsurance}
            alt="Travel Insurance Croatia Osiguranje"
          />
        </div>
        <div className="wrapper pt-5 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <img
            className="mx-auto"
            src={homeInsurance}
            alt="Home Insurance Croatia Osiguranje"
          />
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Osiguranje doma</h3>
            <p>
              Jednom policom osigurajte svoju imovinu i sadržaj u njoj
              štete uzrokovane provalom, požarom, potresom i drugim rizicima.
            </p>
            <div className="price-selection second">
              <ul className="gap-5 flex flex-col">
                <h4>Što je uključeno?</h4>
                <li>Stvari u kućanstvu.</li>
                <li>Građevinski dio.</li>
              </ul>
            </div>
            <div className="flex items-center gap-10 btnInsure">
              <a
                className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
                href="/hr/kontakt"
              >
                Upoznajte nas
              </a>
              <a
                className="border-2 border-oceanBlue py-3 px-7 mt-10 rounded-full text-oceanBlue"
                href={brochure}
              >
                Vidi brošuru
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Offers;
