import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gige from "../../assets/img/gige.webp";
import cafo from "../../assets/img/cafo.webp";
import vranac from "../../assets/img/vranac.webp";

const Houses = () => {
  return (
    <>
      <div>
        <h3 className="third-title text-2xl md:text-3xl lg:text-6xl">
          Our Apartments
        </h3>
        <p className="my-5">
          We offer you travel insurance as well as travel cancellation insurance
          -{" "}
          <a className="text-oceanBlue" href="/insurance">
            Croatia osiguranje
          </a>
          , the leading insurance company in Croatia.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between gap-10 py-10">
          <div className="gige apart flex flex-col items-center text-center justify-between">
            <img className="" src={gige} alt="Gige Apartments first look" />
            <h4>Gige Apartments</h4>
            <p>
              Is a beautiful holiday home in an excellent location. It is
              located in Pelegrin bay, 7 km from the center of Vela Luka.
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/accommodation/gige-apartment"
            >
              See More
            </a>
          </div>
          <div className="cafo apart flex flex-col items-center text-center justify-between">
            <img className="" src={cafo} alt="Čafo Apartments first look" />
            <h4>Čafo Apartments</h4>
            <p>
              Is a beautiful holiday home in an excellent location. It is
              located in Pelegrin bay, 6 km from the center of Vela Luka, and
              only 100 m from the sea.
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/accommodation/cafo-apartment"
            >
              See More
            </a>
          </div>
          <div className="gige apart flex flex-col items-center text-center justify-between">
            <img className="" src={vranac} alt="Gige Apartments first look" />
            <h4>Apartment Vranac</h4>
            <p>
              provides you with everything you need for a perfect vacation for
              two. 30 m2 of interior space and 11 m2 of covered terrace right...
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/accommodation/vranac-apartment"
            >
              See More
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Houses;
