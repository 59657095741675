import Footer from "../components/Footer";
import Contact from "../components/rents/Contact";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Price from "../components/rents/Price";
import Rents from "../components/rents/Rents";

const faqs = [
  {
    question: "Koji dokumenti su mi potrebni za rent-a-car?",
    answer:
      "Prilikom potpisivanja ugovora uz putovnicu ili osobnu iskaznicu potrebno je priložiti važeću vozačku dozvolu.",
  },
  {
    question: "Koja je minimalna dob vozača?",
    answer: "Minimalna dob vozača je 21 godina.",
  },
  {
    question: "Koji je minimalni rok najma?",
    answer: "Minimalno trajanje najma je 12 sati.",
  },
  {
    question: "Je li gorivo uključeno u cijenu?",
    answer:
      "Gorivo nije uključeno u cijenu. Vozilo treba vratiti s istom količinom goriva koju ste pronašli prilikom preuzimanja.",
  },
  {
    question: "Čistoća vozila",
    answer:
      "U slučaju da vozilo zahtijeva posebne postupke čišćenja (npr. uklanjanje mirisa, životinjske dlake, izlijevanja tekućine) po povratku, naplaćuje se minimalna naknada.",
  },
  {
    question: "Može li se rezervacija promijeniti ili otkazati?",
    answer:
      "Rezervaciju je potrebno izvršiti pismenim putem (e-mailom ili GSM-om). Promjene predstavljaju promjenu imena iznajmljivača ili datuma početka i/ili završetka usluge. Ako najmoprimac otkaže rezervaciju do 14 dana prije dogovorenog vremena preuzimanja, naplaćuje se naknada za otkazivanje u iznosu od 15,00 €. Ako najmoprimac otkaže rezervaciju unutar 14 dana prije dogovorenog vremena preuzimanja, ne preuzme unajmljeno vozilo ili otkaže rezervaciju nakon dogovorenog vremena preuzimanja, naplaćuje se naknada za otkazivanje od 20% ukupnog iznosa rezervacije.",
  },
  {
    question: "Koje je osiguranje uključeno u cijenu?",
    answer:
      "Osiguranje od odgovornosti za štetu prouzročenu trećim osobama. CDW (Collision Damage Waiver) - osiguranje od rizika sa sudjelovanjem u naknadi šteta. Odgovornost korisnika za štetu i krađu ograničena je na iznos franšize/sudjelovanja u šteti.",
  },
  {
    question: "Što je franšiza?",
    answer: `
    Odbitak je najveći iznos sudjelovanja u šteti. Visina participacije u šteti ovisi o kategoriji vozila.
    <br/>
    <br/>
    Plaća li se prilikom preuzimanja ili povrata vozila?
    
    <br/>
    <br/>
     
    Prilikom preuzimanja vozila obavezna je kreditna kartica kao jamstvo plaćanja najma na koju se uzima polog, a najam se uvijek naplaćuje prilikom povrata vozila.
    
    <br/>
    <br/>
    Koliko depozita trebam na mojoj kreditnoj kartici?
    
    <br/>
    <br/>
     
    Prilikom preuzimanja vozila uplaćuje se depozit na kreditnu karticu. Polog ovisi o kategoriji vozila i opciji osiguranja koju kupac izabere prilikom najma vozila.
    
    <br/>
    <br/>
    Koja je svrha depozita na kreditnoj kartici?
    
    <br/>
    <br/>
     
    Polog na kreditnoj kartici koristi se za naplatu ugovorene najamnine, eventualne štete, produljenje najma, naknadno uzimanje dodatnih usluga, kazne za parkiranje, kazne za prebrzu vožnju ili slično te za sve ostale naknade proizašle iz najma vozila.
    
    <br/>
    <br/>
    Kada će predautorizacija na mojoj kreditnoj kartici biti poništena?
    
    <br/>
    <br/>
     
    Ukoliko se plaćanje na kraju najma vrši istom karticom na kojoj je izvršena predautorizacija, ostatak sredstava trebao bi biti deblokiran u roku od nekoliko dana. U slučaju plaćanja gotovinom ili drugom kreditnom karticom, blokada sredstava može biti do trideset dana, ovisno o banci.
    
    <br/>
    <br/>
    Ne možemo utjecati na razdoblje za koje će predautorizirani iznos biti deblokiran jer to ovisi o banci izdavatelju kreditne kartice, a ne o nama ili našoj banci.
    
    <br/>
    <br/>
    Je li moguće preuzeti vozilo u jednom gradu i ostaviti ga u drugom?
    
    <br/>
    <br/>
     
    Vozilo je moguće preuzeti u jednom gradu, a vratiti u drugom gradu. Ovu uslugu nazivamo "jednosmjerna" i naplaćuje se prema tarifi, osim ako nije u tijeku neka od akcija gdje je naknada za jednosmjerni najam uključena u najam.
    
    <br/>
    <br/>
    
    Može li više od jedne osobe voziti iznajmljeni automobil?
    
    <br/>
    <br/>
     
    Više osoba može biti uključeno u ugovor o najmu kao vozači. Prilikom preuzimanja vozila svi vozači moraju pokazati vozačku dozvolu i ispunjavati uvjete najma.
    
    <br/>
    <br/>
    Kakav je postupak u slučaju oštećenja/nesreće na vozilu?
    
    <br/>
    <br/>
     
    Ukoliko imate oštećenja na vozilu, potrebno je javiti se poslovnici u kojoj je vozilo preuzeto i policiji radi policijskog zapisnika kako bi se primijenili dogovoreni uvjeti.
    
    <br/>
    <br/>
    Moram li pregledati vozilo prilikom iznajmljivanja?
    
    <br/>
    <br/>
     
    Da, obavezno pregledati vozilo prilikom preuzimanja i usporediti oštećenja na vozilu sa oštećenjima na odjavnici koju ste dobili uz ugovor o najmu.
    
    <br/>
    <br/>
    Ukoliko postoji šteta na vozilu koja nije navedena na obrascu za odjavu, kontaktirajte svoje kolege kako bi označili štetu i izbjegli probleme i troškove štete prilikom povrata vozila.`,
  },
];
const option = [
  {
    option: "Chevrolet Lacetti",
    value: "Chevrolet Lacetti",
  },
  {
    option: "Chevrolet Aveo 1.4",
    value: "Chevrolet Aveo 1.4",
  },
  {
    option: "Chevrolet Aveo 1.2",
    value: "Chevrolet Aveo 1.2",
  },
  {
    option: "VW UP",
    value: "VW UP",
  },
];

const HrrentalCar = () => {
  return (
    <>
      <Navbar />
      <Rents />
      <Price />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default HrrentalCar;
