//flex items-center justify-between
import logo from "../assets/img/logo.png";
const Footer = () => {
  return (
    <>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d23358.393138452182!2d16.715716!3d42.961437!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134a71db969fc033%3A0x3f84237b1dc1db50!2sAtlas%20-%20Tourist%20Agency!5e0!3m2!1sen!2shr!4v1677084362512!5m2!1sen!2shr"
        style={{
          border: "0",
          borderRadius: "10px 10px 0 0",
          width: "100%",
          height: "500px",
        }}
        allowFullScreen={false}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
      <footer className="footer">
        <div className="footer__wrapper">
          <div className="footer_wrap">
            <div className="footer-logo">
              <img src={logo} alt="Atlas Vela Luka footer logo" />
            </div>
            <div className="footer-as">
              <ul>
                <li>
                  <a href="/hr">Naslovna</a>
                </li>
                <li>
                  <a href="/terms-and-conditions">Odredbe i uvjeti</a>
                </li>
                <li>
                  <a href="/policy-and-privacy">Politika privatnosti</a>
                </li>
                <li>
                  <a href="/hr/kontakt">Kontaktirajte nas</a>
                </li>
              </ul>
            </div>
            <div className="info">
              <a
                href="https://goo.gl/maps/LhU437yGSuFeKJQV9"
                className="location"
              >
                Lokacija
              </a>
              <a href="mailto:velalukaatlas@gmail.com" className="telephone">
                Email
              </a>
              <div className="flex flex-col gap-4">
                <a href="tel:+385 91 610 0130">+385 91 610 0130</a>
                <a href="tel:+385 91 915 4396">+385 91 915 4396</a>
              </div>
            </div>
          </div>
          <div className="copyright__wrapper">
            <p className="copyright">
              © 2024 Atlas Vela Luka. Sva prava podržana.
            </p>
            <p>
              Izradio{" "}
              <a
                className="vik underline"
                href="https://www.instagram.com/viktorbilokapic/"
              >
                VB
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
