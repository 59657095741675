import Navbar from "../components/rents/Navbar";
import manInsurance from "../assets/img/tinsurance.webp";
import cancel from "../assets/img/cancel.webp";
import otkaz from "../assets/img/otkaz.png";
import service from "../assets/img/icons/24blue.png"
import covid from "../assets/img/icons/coronablue.png"
import checkamark from "../assets/img/icons/checkmark.svg"
import planet from "../assets/img/icons/planetworld.png"
import Contact from "../components/rents/Contact";
import Footer from "../components/Footer";

const option = [
    {
        option: "Select Option",
        value: "Select Travel Insurance",
    },
    {
        option: "Travel Insurance",
        value: "Travel Insurance",
    },
];
const TravelInsurance = () => {
    return (
        <>
            <section className={"travel-insurance-wrapper"}>
                <Navbar/>
                <div className={"px-4"}>
                    <div className="container mx-auto px-4 rounded-2xl travel-insurance">
                        <div>
                            <h1 className={"bigTitle text-white"}>Travel Insurance</h1>
                            <p>Insure yourself against health care costs during <br/> your stay abroad for up to 45
                                days.
                            </p>
                        </div>
                    </div>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className="container mx-auto px-4 what-included py-10">
                        <div className={"grid grid-cols-1 md:grid-cols-2 items-center"}>
                            <img className={"rounded-2xl w-full md:w-2/3"} src={manInsurance} alt="Man Insurance"/>
                            <div>
                                <h2 className={"main-title"}>What is included?</h2>
                                <ul className={"list-disc py-4"}>
                                    <li>
                                        Medical care and emergency medical expenses
                                    </li>
                                    <li>
                                        Transport by ambulance or helicopter to the nearest hospital
                                    </li>
                                    <li>
                                        Transportation to your home country if you cannot return home as planned due to
                                        health reasons
                                    </li>
                                    <li>
                                        Costs of hospital treatment and transportation of insured persons to their
                                        homeland
                                        due to illness from Covid-19
                                    </li>
                                </ul>
                                <div className={"text-oceanBlue gap-4"}>
                                    <div className={"flex gap-5 py-1"}>
                                        <span>#careleestrip</span>
                                        <span>#safe</span>
                                        <span>#adventure</span>
                                    </div>
                                    <div className={"flex gap-5 py-2"}>
                                        <span>#worryfreetrip</span>
                                        <span>#explore</span>
                                        <span>#saferwithus</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 py-5">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
                        <div className={""}>
                            <img src={service} alt="Service icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>24/7 Telephone assistant service </h3>
                            <p>In case of health problems, we will refer you to the nearest health facility.</p>
                        </div>
                        <div className={""}>
                            <img src={covid} alt="Covid icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>COVID-19 insurance</h3>
                            <p>Due to the illness caused by Covid-19, the costs of hospital treatment and the costs of
                                returning to the homeland are covered.
                            </p>
                        </div>
                        <div className={""}>
                            <img src={planet} alt="Planet icon"/>
                            <h3 className={"py-4 text-oceanBlue font-bold"}>Security around the world</h3>
                            <p>Wherever you travel, insurance protects you during your stay abroad.</p>
                        </div>
                    </div>
                </div>
                <div
                    className={"container mx-auto px-4 py-20 text-center flex flex-col justify-self-center items-center gap-5"}>
                    <h1 className={"bigTitle"}>Travel cancellation <br/> insurance</h1>
                    <p className={"text-[#a3a3a3]"}>In case of cancellation of the trip, <br/> get a refund of up to 90%
                        of the amount you paid to the
                        travel agency.</p>
                    <img className={"rounded-2xl"} src={otkaz} alt="Otkaz putovanja"/>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className={"container mx-auto px-4 py-20"}>
                        <h2 className={"main-title"}>Why contract Croatia Osiguranje <br/> cancellation insurance?</h2>
                        <p className={"text-[#a3a3a3]"}>Trip cancellation insurance is insurance that protects you from
                            financial loss in the event that
                            you are forced to cancel your trip due to illness, accidents or natural disasters.</p>
                        <div className={"flex gap-4 pt-10 items-center"}>
                            <img src={checkamark} alt="Checkmark icon"/>
                            <p className={"text-[#a3a3a3]"}>Get a refund for unused days, even if you end your trip
                                after it's already started.</p>
                        </div>
                    </div>
                </div>
                <div className={"my-10"}>
                    <div className="container mx-auto px-4 what-included py-10">
                        <div className={"grid grid-cols-1 md:grid-cols-2 items-center"}>
                            <div>
                                <h2 className={"main-title"}>What is included?</h2>
                                <p>Up to 90% of the paid trip amount in case of trip cancellation due to:</p>
                                <ul className={"list-disc py-4"}>
                                    <li>
                                        illness (of the insured, life partner, children, parents)
                                    </li>
                                    <li>
                                        accident
                                    </li>
                                    <li>
                                        natural disasters in the insured's place of residence
                                    </li>
                                    <li>
                                        death (of the insured, life partner, children, parents)
                                    </li>
                                    <li>
                                        military exercises
                                    </li>
                                </ul>
                                <div className={"text-oceanBlue gap-4"}>
                                    <div className={"flex gap-5 py-1"}>
                                        <span>#careleestrip</span>
                                        <span>#safe</span>
                                        <span>#adventure</span>
                                    </div>
                                    <div className={"flex gap-5 py-2"}>
                                        <span>#worryfreetrip</span>
                                        <span>#explore</span>
                                        <span>#saferwithus</span>
                                    </div>
                                </div>
                            </div>
                            <img className={"rounded-2xl w-full md:w-2/3"} src={cancel} alt="Man Insurance"/>
                        </div>
                    </div>
                </div>
                <div className={"bg-[#FBFBFB] my-10"}>
                    <div className={"container mx-auto px-4 py-20"}>
                        <h2 className={"main-title"}>Useful information</h2>
                        <div className={"grid grid-cols-1 lg:grid-cols-2 justify-center pt-5"}>
                            <div className={"py-5"}>
                                <h3 className={"third-title"}>When it can be arranged?</h3>
                                <p className={""}>Insurance can be arranged within three days of concluding a travel contract or purchasing an airline ticket and paying for accommodation</p>
                            </div>
                            <div className={"py-5"}>
                                <h3 className={"third-title"}>When it is possible to cancel the trip?</h3>
                                <p className={""}>Due to the occurrence of an insured event, the trip can be canceled before departure or interrupted during the trip. If you interrupt a trip that has already started, you are entitled to 90% of the amount paid for the days you did not use.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-4 my-10">
                    <Contact option={option}/>
                </div>
                <Footer/>
            </section>
        </>
    )
}

export default TravelInsurance