import findUs from "../assets/img/findus.jpg";
import crsig from "../assets/img/crsig-logo.svg";
const FindUs = () => {
  return (
    <section className="container mx-auto px-4">
      <h2 className="main-title text-oceanBlue">Kako nas pronaći?</h2>
      <div className="who-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center py-5 gap-5">
        <div className="about-us-wrapper">
          <h3>Najlakši način bez stresa</h3>
          <div className="texts">
            <p>
              Nudimo vam najbolje, najsigurnije i najbrže rješenje za dolazak do
              prekrasnog otoka Korčula.
            </p>
            <p>
              Izbjegnite gužve i nepotrebnu nervozu iznajmljivanjem naših
              automobila, skutera, čamaca... koji su osigurani u suradnji s{" "}
              <a className="text-oceanBlue" href={"/hr/osiguranje"}>
                Croatia Osiguranje d.d.
              </a>
            </p>
            <p className="mb-10">
              Nudimo Vam putno osiguranje i osiguranje u slučaju otkazivanja
              putovanja.
            </p>
            <img src={crsig} alt="CRSIG LOGO" className="py-10" />
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upitajte nas !
            </a>
          </div>
        </div>
        <img
          src={findUs}
          className="rounded-xl"
          alt="Korčula Island Vela Luka City Center"
        />
      </div>
    </section>
  );
};

export default FindUs;
