import Navbar from "../components/rents/Navbar";
import fish from "../assets/img/fish.jpg";
import Faq from "../components/rents/Faq";
import Footer from "../components/Footer";
import Contact from "../components/rents/Contact";

const faqs = [
  {
    question: "Sportski ribolov",
    answer: `
    <p><b>Sportski ribolov</b> reguliran je Zakonom o morskom ribarstvu i Pravilnikom o športskom i rekreacijskom ribolovu na moru. Na ovaj oblik ribolova odgovarajuće se primjenjuju i odredbe drugih propisa kojima se uređuju ribolovni alati i oprema, ribolov i zaštita riba i drugih vodenih organizama.</p>
    <p>Za športski ribolov u ribolovnom moru Republike Hrvatske potrebna je dozvola, bez obzira obavlja li se s obale ili iz brodice.</p>
    <p>Podvodni ribolov dopušten je isključivo u sportskom ribolovu.</p>
    <p><b>Smiju li se djeca baviti sportskim ribolovom?</b></p>
    <p>Da, ali bez podvodne puške. Dozvola ne vrijedi za podvodni ribolov za osobe mlađe od 16 godina.</p>
    <p>Ribolovom velike ribe smatra se ribolov s opremom preko 30 lb. Ne postoje ograničenja za ručno trolanje.</p>
    <p>Godišnja posebna dozvola za lov na veliku ribu može se kupiti samo uz godišnju športsku dozvolu.</p>
    <p>
    Osim 150-dnevnih, ne mogu se kupiti dnevne i višednevne (1, 3 i 7 dana) te posebne dozvole. Posebne dozvole moguće je kupiti samo uz prethodno kupljenu godišnju sportsku dozvolu do kraja tekuće godine
    <br/>
    Za lov na tunu potrebna je posebna dozvola za lov na veliku ribu i samo C&R sustav ribolova (uhvati i pusti).
    <br/>
    U športskom ribolovu nije dopušten gospodarski ribolov tune, jegulje i jegulje.
    <br/>
    Jednodnevne i višednevne dozvole za športski ribolov na moru imaju osobe članice nacionalnog saveza za športski ribolov, a godišnje dozvole za 150 dana imaju isključivo članovi Hrvatskog saveza za športski ribolov na moru - HSSRM. .
    </p>
    <p><b>Vrste dozvola za sportski ribolov:</b></p>
    <ol>
      <li>1. godišnje sportske dozvole koje vrijede za kalendarsku godinu</li>
      <li>2. jednodnevne sportske dozvole</li>
      <li>3. višednevne sportske dozvole</li>
    </ol>
    <p>Godišnja športska dozvola za osobe starije od 65 godina s prebivalištem na otocima i poluotoku Pelješcu, kako je određeno posebnim propisom o otocima, ne izdaje se.</p>
    `,
  },
  {
    question: "Dopušteni ribolovni alati",
    answer: `
    <p>Sukladno članku 4. Pravilnika o športskom i rekreacijskom ribolovu (NN122/17), ribič koji ima GODIŠNJU ODOBRENOST za športski ribolov može loviti s plovila ili obale sljedećim vrstama i količinama ribolovnih alata i opreme:</p>
    
    <ol>    
      <li>1. po otpadu, ukupno do dva (2) komada; dopuštena je uporaba štapa i koluta</li>
      <li>2. kančenica, ukupno do dva (2) komada; dopuštena je uporaba štapa i koluta</li>
      <li>3. uzicu ili panulu, ukupno do dva (2) komada</li>
      <li>4. s udicom za lov glavonožaca, ukupno do dva (2) komada.</li>
      <li>5. naprava za lov na veliku morsku glistu (trapula), ukupno do dva (2) komada</li>
      <li>6. ukupno dvije (2) podvodne puške. Dozvola ne vrijedi za podvodni ribolov za osobe mlađe od 16 godina.</li>
    </ol>
    <p>Ribič koji peca iz čamca smije koristiti štap i rolu nosivosti manje od 30 lb.</p>
    <p>Ribiči s godišnjim dozvolama imaju pravo kupiti POSEBNE DOZVOLE za korištenje dodatnih alata za koje je izdana posebna dozvola, a koje se izdaju za ribolov:</p>

    <ol>  
      <li>stojeći parangal, (jedan ili više njih) s ukupno do 100 udica</li>
      <li>kosti, ukupno jedan (1) komad</li>
      <li>štapovi za pecanje, ukupno do tri (3) komada.</li>
      <li>za korištenje umjetne rasvjete (feral) jedno rasvjetno tijelo maksimalne snage 40 W za žarulju sa žarnom niti, 30 W za halogenu žarulju i 10 W za LED žarulju po plovilu, isključivo u ribolovu kopljem ili strunom s udica za lov glavonožaca.</li>
      <li>ribolov udičarskom opremom za veliku ribu s najviše tri pribora (štap i rola) s jednom udicom na svakom priboru ili jednom varalicom na svakom priboru. Pribor za veliku ribu je sustav koji se sastoji od štapa za pecanje, koluta i baze (najlona ili strune) pri čemu svaki dio sustava ima nosivost od najmanje 30 lb ili više.</li>
    </ol>
    `,
  },
  {
    question: "Dopuštene količine ulova",
    answer: `
    <p>
    U športskom ribolovu dopušteno je uloviti i sakupiti do pet kilograma ribe i drugih morskih organizama dnevno po ribolovcu, a dopuštena dnevna količina ulova može biti veća za masu jedne ribe ili drugog morskog organizma koja prelazi dopuštenu pet kilograma. Dopušteno je sakupljanje morskih organizama: školjkaša, puževa i mnogočetinaša, a udio živih školjkaša i puževa u dopuštenom dnevnom ulovu može biti do 2 kilograma, osim dagnje (Mytilus galloprovincialis) koja može biti do 5 kilograma.
    </p>`,
  },
  {
    question: "Do kada se može kupiti?",
    answer: `
    <p><b>GODIŠNJI SPORT</b> licenca se prodaje od 1.12.2023. do 1.3.2024</p>
    <p><b>POSEBNA DOZVOLA</b>, uz prethodno kupljenu godišnju sportsku dozvolu, ribiči mogu kupiti do kraja tekuće godine (31.12.2024.).</p>
    <p><b>DOZVOLA ZA 1 DAN</b> dozvola se prodaje od 15.12.2023. do kraja tekuće godine (31.12.2024.).</p>
    <p><b>DOZVOLA ZA 3 DANA</b> dozvola se prodaje od 15.12.2023. do kraja tekuće godine (31.12.2024.).</p>
    <p><b>DOZVOLA ZA 7 DANA</b> dozvola se prodaje od 15.12.2023. do kraja tekuće godine (31.12.2024.).</p>
    `,
  },
  {
    question: "Trebaju li djeca imati dozvolu za rekreacijski ribolov?",
    answer:
      "Ne, osobe do 14 godina mogu se baviti rekreacijskim ribolovom jednim ulovom i jednom strunom (sa ili bez štapa) bez ribolovne dozvole.",
  },
  {
    question:
      "Koje je pravilo korištenja podvodne puške u rekreacijskom morskom ribolovu za pojedince?",
    answer:
      "Ribolov podvodnom puškom nije dopušten uz dozvolu za rekreacijski ribolov. Ribolov podvodnom puškom dopušten je uz dozvolu za ŠPORTSKI ribolov.",
  },
];
const option = [
  {
    option: "Odaberi opciju",
    value: "Fishing Licence",
  },
  {
    option: "Ribarska dozvola",
    value: "Fishing Licence",
  },
];

const HrFishLicence = () => {
  return (
    <>
      <Navbar />
      <div className="fish-wrapper fishing">
        <section className="container mx-auto px-4 py-6 ">
          <h1 className="bigTitle">Rekreacijski i sportski ribolov</h1>
          <p>
            Uplata za dozvolu se vrši na poslovni račun ili na
            klupski račun (ovisno radi li se o REKREACIJI ili SPORTU
            dopuštenje), a dopuštenje i/ili članska iskaznica bit će poslana na Vašu adresu
            e-poštom u .PDF formatu.
          </p>
          <img
            className="rounded-xl w-full"
            src={fish}
            alt="Fishing licence for sports"
          />
        </section>
        <Faq faqs={faqs} />
      </div>
      <div className="container mx-auto px-4 py-6 ">
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default HrFishLicence;
