import fish from "../../assets/img/fish.webp";
import modra from "../../assets/img/modra.webp";
import stiniva from "../../assets/img/uvala.webp";
import prodzid from "../../assets/img/prodzid.webp";
import bigGame from "../../assets/img/bigame.jpg";
import hvar from "../../assets/img/hvar.jpg";
import game from "../../assets/img/game.jpg";
const Trips = () => {
  return (
    <>
      <section className="container mx-auto px-4 offer-insurance">
        <h1 className="bigTitle">
          Explore beautiful beaches and locations in Croatia near Korčula.
        </h1>
        <div className="wrapper travel pt-20 grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">
              Modra špilja + otok Vis, uvala Stiniva
            </h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Vela Luka ( City Center )
              </p>
              <p>
                <span>Starting time: </span>8:00 AM
              </p>
              <p>
                <span>Arrival time: </span>9:15 AM
              </p>
              <p>
                After arrival, transfer to smaller boats to go to the cave for
                sightseeing. The return to the ship follows and the journey
                continues towards the island of Vis - Komiža. During the trip,
                guests will have free time in Komiža.
              </p>
              <p>
                A break for swimming is planned during the trip. The famous
                beach Stiniva is most visited, but due to the crowds during the
                summer months. Lunch is not included in the price (restaurants
                in the town of Komiža with good prices and good food recommended
                by the skipper)
              </p>
              <p className="not-included">
                *The price does not include a ticket to the Blue Cave.
                <br />
                <br />
                *The trip is organized in smaller groups of 6-10 passengers (for
                larger groups we may organize a trip with two boats
              </p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/contact"
            >
              Meet with us
            </a>
          </div>
          <img
            className="mx-auto rounded-full"
            src={modra}
            alt="Modra špilja + otok Vis vacation trip"
          />
        </div>
        {/* <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <img
            className="mx-auto rounded-full"
            src={uvala}
            alt="Uvala stiniva vacation trip"
          />
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Uvala Stiniva</h3>
            <p>
              Insure yourself against health care costs during your stay in
              abroad for 45 days.
            </p>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Vela Luka ( City Center )
              </p>
              <p>
                <span>Starting time: </span>According to agreement
              </p>
              <p>
                It is located on the southern side of the island of Vis,
                bordered by high cliffs that protect a beautiful pebble beach.
                It is an idyllic place for swimming in the crystal clear sea
                that will remain in your memory forever. Due to the natural
                shade, it is also suitable for families with small children.
              </p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/contact"
            >
              Meet with us
            </a>
          </div>
        </div> */}
        <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <div>
            {/* <img
              className="mx-auto rounded-full"
              src={bigGame}
              alt="Uvala stiniva vacation trip"
            /> */}
            <img
              className="rounded-full text-center "
              src={game}
              alt="Big game fishing"
            />
          </div>
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Big Game fishing</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Vela Luka with Merry Fisher 700
                boat
              </p>
              <p>
                <span>Starting time: </span>The trip starts in the early morning
                hours (6-7am)
              </p>
              <p>
                Big game fishing is a type of sport fishing in the open sea, as
                far from land as possible. It is characterized by hunting large
                fish such as tuna and swordfish. For all of you who crave
                adrenaline, our Proizd team organizes one-day fishing under the
                leadership of captain Nikola Žuvela. Nikola has been in love
                with the sea since childhood and has many years of experience in
                fishing.
              </p>
              <p>
                Still relatively unexploited southern and southwestern area of
                the Korčula archipelago and the area around Lastovo, Sušac,
                Palagruže, Hvar, Šćedar, Biševa, Vis and other islands, ideal
                are for this type of fishing.
              </p>
              <p>
                The return is in the late afternoon (around 6 pm) depending on
                the agreement.
              </p>
              <p>Price on request, depends on the number of people.</p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/contact"
            >
              Meet with us
            </a>
          </div>
        </div>
        <div className="wrapper travel pt-20 pb-20 grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Fish picnic</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Vela Luka ( City Center )
              </p>
              <p>
                <span>Starting time: </span>According to agreement
              </p>
              <p>
                <span>Arrival time: </span>The schedule depends on your wishes
                and requirements
              </p>
              <p>
                <span>Price: </span>The price depends on the number of people,
                and children have a discount.
              </p>
              <p>
                We organizes a fish picnic for you in a traditional stone house
                on Proizd. Proizd is an island 7 nautical miles from Vela Luka,
                on the northern side of Vela Luka bay. It is famous for its
                beautiful pebble beaches and turquoise sea, and as a recognition
                for it, it received the award for the most beautiful Croatian
                beach in 2007 (Bili Boki beach on the western coast of the
                islet). All-day enjoyment is filled with lunch according to your
                wishes, and in your free time you can swim and tour the island.
                The trip is meant for everyone, and the Batala beach near the
                stone house is suitable for children.
              </p>
              <p>
                Transportation, driver, lunch (fish or meat) and drinks are
                included the price. We recommend bringing a bathing suit, towel,
                face cream sun and diving equipment.
              </p>
            </div>
            <div className="flex items-center gap-10 btnInsure">
              <a
                className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
                href="/contact"
              >
                Meet with us
              </a>
              <a
                className="border-2 border-oceanBlue py-3 px-7 mt-10 rounded-full text-oceanBlue"
                href="/fish-licence"
              >
                Fishing Licence
              </a>
            </div>
          </div>
          <img
            className="mx-auto rounded-full"
            src={fish}
            alt="Fish Picnic + Fish Licenses"
          />
        </div>
        <div className="wrapper pt-20 grid md:flex-col lg:grid-cols-2 items-center gap-5">
          <img
            className="mx-auto rounded-full"
            src={hvar}
            alt="Hvar and Paklinski otoci"
          />
          <div className="left-insurance-content">
            <h3 className="insuranceTitle">Hvar + Paklinski otoci</h3>
            <div className="price-selection second third flex flex-col gap-5">
              <p>
                <span>Starting point: </span>Departure from Vela Luka (Korčula
                island), exact meeting place as agreed
              </p>
              <p>
                <span>Starting time: </span>9h *not fixed
              </p>
              <p>
                <span>Return time: </span>17h *not fixed
              </p>
              <p>
                <span>Driving time: </span>50 min
              </p>
              <p>
                Spend an unforgettable day on the island that holds the title of
                the sunniest place in Croatia, counting a total of 2,700 sunny
                hours a year - sail with us to the island of Hvar!
              </p>
              <p>
                Hvar is not only known for its pleasant climate and crystal sea,
                but also for its rich cultural and historical heritage. Visit
                the oldest surviving theater in Europe, founded in 1612, located
                in the town of Hvar. Take a walk around the market where the
                cathedral of St. Stjepan and beautiful palaces and public
                buildings from the 15th, 16th and 17th centuries (Bishop's
                Palace, Paladini, Leroj, Loggia, etc.). Passing through the main
                city gate Porta di Datallo, the stairs will take you towards the
                Hvar tower Forteca, or as the Hvar people call it Španjola.
                Today, the fortress houses a collection of amphorae and various
                other works of art from the Middle Ages. In addition to the
                beautiful stone architecture, you will also have the opportunity
                to enjoy the panorama of the city, the harbor of Hvar and the
                nearby Paklin Islands.
              </p>
              <p>
                The Paklin Islands are the perfect destination for relaxing away
                from the hustle and bustle of the city, only ten minutes away by
                boat from the port of Hvar. Spend the day exploring the Paklin
                Islands, enjoy secret coves, swim in the crystal blue sea and
                relax in peace and untouched nature. The Paklin Islands are
                certainly the most beautiful part of the Hvar Riviera.
              </p>
              <p>Price on request, depends on the number of people.</p>
            </div>
            <a
              className="bg-oceanBlue py-3 px-7 mt-10 rounded-full text-white"
              href="/contact"
            >
              Meet with us
            </a>
          </div>
        </div>
        <div className="px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
          <img
            className="rounded-xl h-full w-full"
            src={stiniva}
            alt="Bicycle rental"
          />
          <img
            className="rounded-xl h-full w-full"
            src={prodzid}
            alt="Bicycle rental"
          />
        </div>
      </section>
    </>
  );
};

export default Trips;
