import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Location from "../components/insurance/Location";
import Why from "../components/insurance/Why";
import Navbar from "../components/rents/Navbar";
import crsig from "../assets/img/crsig-logo.svg";
import Offers from "../components/insurance/Offers";
import Contact from "../components/rents/Contact";

import boat from "../assets/img/boat-sec.jpg";

const option = [
  {
    option: "Home insurance",
    value: "Home insurance",
  },
  {
    option: "Travel insurance",
    value: "Travel insurance",
  },
  {
    option: "Insurance question",
    value: "Insurance question",
  },
];

const Insurance = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);
  return (
    <>
      {showButton && (
        <img
          className="fixed right-5 bottom-40"
          src={crsig}
          alt="Croatia Osiguranje Partners logo"
        />
      )}
      <Navbar />
      <Offers />
      <Why />
      <div className="container mx-auto px-4 pb-5">
        <h2 className="main-title py-5">
          Why contract compulsory boat and yacht insurance in Croatia
          Osiguranje?
        </h2>
        <img src={boat} className="rounded-xl" alt="" />
        <p className="py-5 text-xl">
          Why should CroatiaOsiguranje compulsory insurance for boats and
          yachts?
        </p>
        <p>
          Compulsory boat insurance covers the liability of the owner or user of
          the boat or yacht for compensation for damages due to death, bodily
          injury, damage to the health of swimmers, divers or people on land.
          Vessel owners are obliged to insure the vessel every year, and
          insurance is contracted for one year. What is covered? Liability for
          damage you cause to others, in case you kill, injure or harm someone
          with your vessel The insured amount is prescribed by law and amounts
          to €464,529.83 (HRK 3,500,000.00) per event. Additional options
          Voluntary accident insurance Insurance of the person driving the
          vessel and the passengers from the consequences of an accident.
        </p>
      </div>
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Contact option={option} />
        <Location />
      </div>
      <Footer />
    </>
  );
};

export default Insurance;
