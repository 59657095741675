import React, { Suspense } from "react";
import "./styles/Main.scss";

import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// Pages imports

import Home from "./pages/index";
import Car from "./pages/car-rental";
import Boat from "./pages/boat-rental";
import Scooter from "./pages/scooter-rental";
import Bike from "./pages/bicycle-rental";
import Excursion from "./pages/excursion";
import Tickets from "./pages/tickets";
import Insurance from "./pages/insurance";
import TravelInsurance from "./pages/travel-insurance";
import Contact from "./pages/contact";
import FishLicence from "./pages/fish-licence";
import RealEstate from "./pages/real-estate";
import Accommodation from "./pages/accommodation";
import Gige from "./pages/gige";
import Cafo from "./pages/cafo";
import Vranac from "./pages/vranac";
import PolicyAndPrivacy from "./pages/policy-and-privacy";
import TermsAndConditions from "./pages/terms-and-conditions";
import Loading from "./pages/loading";
import wap from "./assets/img/whatsapp.png";
import NotFound from "./pages/not-found";

import HrHome from "./hr/pages/index";
import HrExcursion from "./hr/pages/excursion";
import HrCar from "./hr/pages/car-rental";
import HrBoat from "./hr/pages/boat-rental";
import HrScooter from "./hr/pages/scooter-rental";
import HrBike from "./hr/pages/bicycle-rental";
import HrTickets from "./hr/pages/tickets";
import HrInsurance from "./hr/pages/insurance";
import HrContact from "./hr/pages/contact";
import HrFishLicence from "./hr/pages/fish-licence";
import HrRealEstate from "./hr/pages/real-estate";
import HrAccommodation from "./hr/pages/accommodation";
import HrGige from "./hr/pages/gige";
import HrCafo from "./hr/pages/cafo";
import HrVranac from "./hr/pages/vranac";
import HrTravelInsurance from "./hr/pages/travel-insurance";
import HrPolicyAndPrivacy from "./hr/pages/policy-and-privacy";
import HrTermsAndConditions from "./hr/pages/terms-and-conditions";
import HrLoading from "./hr/pages/loading";
import HrNotFound from "./hr/pages/not-found";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };
  const location = useLocation();
  return (
    <>
      {showButton && (
        <>
          <a className="toTop" href="https://wa.me/+385916100130">
            <img src={wap} alt="Whatsapp" />
          </a>
          <button onClick={scrollToTop} className="toTopBtn">
            <i className="fas fa-arrow-up"></i>
          </button>
        </>
      )}
      <Suspense fallback={<Loading />}>
        <Routes location={location} key={location.pathname}>
          <Route path="/" element={<Home />} />
          <Route path="/car-rental" element={<Car />} />
          <Route path="/boat-rental" element={<Boat />} />
          <Route path="/scooter-rental" element={<Scooter />} />
          <Route path="/bicycle-rental" element={<Bike />} />
          <Route path="/excursion" element={<Excursion />} />
          <Route path="/insurance" element={<Insurance />} />
          <Route path="/insurance/travel-insurance" element={<TravelInsurance />} />
          <Route path="/tickets" element={<Tickets />} />
          <Route path="/real-estate" element={<RealEstate />} />
          <Route path="/accommodation" element={<Accommodation />} />
          <Route path="/accommodation/gige-apartment" element={<Gige />} />
          <Route path="/accommodation/cafo-apartment" element={<Cafo />} />
          <Route path="/accommodation/vranac-apartment" element={<Vranac />} />
          <Route path="/fish-licence" element={<FishLicence />} />
          <Route path="/contact" element={<Contact />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="*" element={<NotFound />} />
          {/* <Route path="*">
            <Navigate to="atlas.com.hr" />
          </Route> */}
          <Route path="/policy-and-privacy" element={<PolicyAndPrivacy />} />
          {/* Croatian language */}
          <Route path="/hr/" element={<HrHome />} />
          <Route path="/hr/putovanja" element={<HrExcursion />} />
          <Route path="/hr/karte" element={<HrTickets />} />
          <Route path="/hr/najam-auta" element={<HrCar />} />
          <Route path="/hr/najam-brodova" element={<HrBoat />} />
          <Route path="/hr/najam-skutera" element={<HrScooter />} />
          <Route path="/hr/najam-bicikli" element={<HrBike />} />
          <Route path="/hr/osiguranje" element={<HrInsurance />} />
          <Route path="/hr/osiguranje/putno-osiguranje" element={<HrTravelInsurance />} />
          <Route path="/hr/nekretnine" element={<HrRealEstate />} />
          <Route path="/hr/smjestaj" element={<HrAccommodation />} />
          <Route path="/hr/smjestaj/gige-apartman" element={<HrGige />} />
          <Route path="/hr/smjestaj/cafo-apartman" element={<HrCafo />} />
          <Route path="/hr/smjestaj/vranac-apartman" element={<HrVranac />} />
          <Route path="/hr/ribarska-dozvola" element={<HrFishLicence />} />
          <Route path="/hr/kontakt" element={<HrContact />} />
          <Route
            path="/terms-and-conditions"
            element={<HrTermsAndConditions />}
          />
          <Route path="*" element={<HrNotFound />} />
          {/* <Route path="*">
            <Navigate to="atlas.com.hr" />
          </Route> */}
          {/* <Route path="/policy-and-privacy" element={<HrPolicyAndPrivacy />} */}
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
