const Header = () => {
  return (
    <>
      <section className="ticket-header my-5 container mx-auto px-4 rounded-xl">
        <h1 className="text-white bus-title">
          Udobno putovanje uz pristupačnu cijenu s nama.
        </h1>
      </section>
    </>
  );
};

export default Header;
