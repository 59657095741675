import modraspilja from "../../assets/img/modra-spilja.jpg";
import uvalastiniva from "../../assets/img/uvala-stiniva.jpg";
import fishpicnic from "../../assets/img/fish-picnic.jpg";
import bigGame from "../../assets/img/bigGame.jpg";
interface Destination {
  img: any;
  id: number;
  title: string;
  btnInfo: string;
  grade: string;
  image: {
    src: any;
    alt: string;
  };
  quote: string;
  route: string;
}

const destination: Destination[] = [
  {
    id: 1,
    title: "Modra špilja + Island Vis",
    btnInfo: "Book now",
    grade: "4.4",
    image: {
      src: modraspilja,
      alt: "Visit Modra Špilja and Island Vis",
    },
    img: modraspilja,
    quote: "Best to visit",
    route: "excursion",
  },
  {
    id: 2,
    title: "Big Game Fishing",
    btnInfo: "Book now",
    grade: "4.4",
    image: {
      src: bigGame,
      alt: "Visit The stunning Uvala Stiniva",
    },
    img: bigGame,
    quote: "Adrenaline",
    route: "excursion",
  },
  {
    id: 3,
    title: "Fish Picnic + certificate",
    btnInfo: "Book now",
    grade: "4.4",
    image: {
      src: fishpicnic,
      alt: "Visit and explore the fish picnic",
    },
    img: fishpicnic,
    quote: "Adventure",
    route: "excursion",
  },
];

export default destination;
