import Footer from "../components/Footer";
import Bicycle from "../components/rents/Bicycle";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Contact from "../components/rents/Contact";
import PriceBic from "../components/rents/PriceBic";

const faqs = [
  {
    question: "Može li se rezervacija promijeniti ili otkazati?",
    answer:
      "Otkazivanje najma bicikla podliježe naknadi za otkazivanje koja varira ovisno o vremenu obavijesti koju ste dobili prije datuma početka najma. Otkazivanje unutar 5 dana od datuma kada trebate unajmiti bicikl, naplatit će vam se puni trošak najma, a novac za more neće biti vraćen. Otkazivanja između 2 tjedna koliko želite otkazati, bit će vam naplaćeno 80% naknade za otkazivanje povrata. Otkazivanje između 2 i 4 tjedna koje želite otkazati, povrat će vam biti naplaćen u iznosu od 50% naknade za otkazivanje. Otkazivanja više od mjesec dana (30 dana) prije datuma rezerviranih bicikala neće se naplatiti. Otkazivanje mora biti primljeno u pisanom obliku ili putem e-pošte ili WhatsApp-a.",
  },
  {
    question: "Što ako nam bicikl ukradu tijekom trajanja najma?",
    answer:
      "Ako vam netko ukrade bicikl ili ako izgubite dodatnu opremu za bicikl, kao što su brave ili pribor za popravak, vi ste odgovorni za gubitak, prema uvjetima našeg Ugovora o najmu. Ako vam ukradu bicikl, morate nas odmah obavijestiti, a ako kod kuće imate osiguranje koje može platiti ukradeni bicikl, morat ćete zatražiti policijsko izvješće u lokalnoj policijskoj postaji. Za nedostajuću biciklističku opremu naplaćujemo njezinu maloprodajnu vrijednost, provjerite u cjeniku.",
  },
  {
    question: "Što mi je potrebno za najam bicikla?",
    answer:
      "Za ispunjavanje Ugovora o najmu potreban je važeći dokument (osobna iskaznica, vozačka dozvola ili putovnica).",
  },
  {
    question: "Vraćanje bicikla?",
    answer:
      "Pravilo je da svu svoju opremu vratite najmanje 15 minuta prije zatvaranja kako biste imali vremena za odjavu. U slučaju lošeg vremena, možete vratiti bicikl i promijeniti termin najma (nazovite). Svi zakašnjeli povrati mogu se uzeti u obzir unutar minimalnih kašnjenja, ali preko toga podliježu naknadama za prekovremeni rad.",
  },
  {
    question: "Što trebam učiniti ako mi pukne guma?",
    answer:
      "Jako nam je žao kada se to dogodi. To se može dogoditi s vremena na vrijeme i to je nešto izvan naše kontrole. Nudimo GRATIS kit za popravak guma i malu zračnu pumpu za najam duži od 3 dana, inače ga možete iznajmiti za 4 € po danu, a ako znate kako popraviti probušenu gumu, možete nastaviti pedalirati. Ako ne znate kako se nositi sa situacijom - kontaktirajte nas, doći ćemo u najkraćem mogućem roku i riješiti problem; bit će vam naplaćeni dijelovi, popravak i pomoć. Nemojte se voziti na probušenoj gumi - to oštećuje kotač. Ako vozite na probušenoj gumi, možda ćete morati platiti.",
  },
  {
    question:
      "Koja je odgovornost najmoprimca u vezi s održavanjem i popravkom bicikla tijekom trajanja najma i koje su posljedice za izgubljenu, ukradenu ili oštećenu opremu?",
    answer:
      "Iako su svi naši bicikli profesionalno servisirani prije slanja, bicikli će možda trebati podešavanje ili održavanje tijekom razdoblja najma; takvo održavanje će se provoditi o trošku najmoprimca. Kupac je suglasan da će vratiti opremu u jednako dobrom stanju u kakvom je bila primljena, prihvaćajući uobičajeno trošenje i habanje, te popraviti i zamijeniti izgubljene ili ukradene, oštećene ili pokvarene bicikle ili dijelove ili nadoknaditi Cycling-Rentals za navedenu opremu. Stoga, bez obzira na stranu koja je kriva, kupac razumije i prihvaća odgovornost za krađu ili oštećenje navedene opreme.",
  },
  {
    question:
      "Za koje događaje tijekom najma bicikla Cycling Rentals nije odgovoran?",
    answer:
      "Cycling Rentals nije odgovoran za sljedeće događaje tijekom iznajmljivanja bicikla: podešavanje zupčanika / probijanje / slomljene žbice / slomljeni lanci / slomljeni mjenjači / slomljeni drop-out / oštećenje obruča kotača / poderana sjedala / ogoljeni navoji na poluzi pedale / oštećenje izvan kontrole iznajmljivanja bicikla i rezultat korištenja ili zlouporabe vozača.",
  },
];
const option = [
  {
    option: "Mountain Bike Cube",
    value: "Mountain Bike Cube",
  },
];
const HrBicycleRental = () => {
  return (
    <>
      <Navbar />
      <Bicycle />
      <PriceBic />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default HrBicycleRental;
