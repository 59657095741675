import car from "../../assets/img/icons/car-rental.png";
import FishingPoleEmoji from "../../assets/img/icons/fishing.png";
import Bus from "../../assets/img/icons/bus-ticket.png";
import Guarantee from "../../assets/img/icons/insurance.png";
import FloatingIslandBeach from "../../assets/img/icons/camping-tent.png";
import apartment from "../../assets/img/icons/real-estate.png";
import cr from "../../assets/img/icons/cr.svg";

interface Categories {
  id: number;
  title: string;
  desc: string;
  image: {
    src: any;
    alt: string;
  };
  stars: number;
  route: string;
  logo?: {
    image?: any;
    alt?: string;
  };
}

const category: Categories[] = [
  {
    id: 1,
    title: "Renta auta, skutera, brodova",
    desc: "500+ zadovoljnih klijenata",
    image: {
      src: car,
      alt: "Najbolja ponuda",
    },
    stars: 5,
    route: "/hr/najam-auta",
  },
  {
    id: 2,
    title: "Putovanja",
    desc: "150+ organiziranih putovanja",
    image: {
      src: FloatingIslandBeach,
      alt: "Ekskurzije",
    },
    stars: 5,
    route: "/hr/putovanja",
  },
  {
    id: 3,
    title: "Osiguranje",
    desc: "Osiguravamo vas u svakom pogledu",
    image: {
      src: cr,
      alt: "Guarantee",
    },
    stars: 5,
    route: "/hr/osiguranje",
  },
  {
    id: 4,
    title: "Nekretnine",
    desc: "Kupi ili prodaj nekretnine",
    image: {
      src: apartment,
      alt: "Kuće za rent",
    },
    stars: 5,
    route: "/hr/nekretnine",
  },
  {
    id: 5,
    title: "Karte",
    desc: "Autobus, brod, avion...",
    image: {
      src: Bus,
      alt: "Kupi karte za autobus, avion ili brod",
    },
    stars: 5,
    route: "/hr/karte",
  },
  {
    id: 6,
    title: "Ribarske dozvole",
    desc: "Sportsko ribarenje",
    image: {
      src: FishingPoleEmoji,
      alt: "Dobij licencu za ribarenje",
    },
    stars: 5,
    route: "/hr/ribarska-dozvola",
  },
];

export default category;
