const Avio = () => {
  return (
    <section className="ticket-header airplane my-5 container mx-auto px-4 rounded-xl">
      <div className="avio-content">
        <h2 className="text-white avio-title">
          Zrakoplovne karte za sve destinacije.
        </h2>
        <p>
          Možemo vam pronaći avio kartu s polaskom iz Zagreba, Splita, Dubrovnika
          za sve europske i svjetske destinacije poput Londona, Pariza, New
          York...
        </p>
        <a
          className="bg-oceanBlue py-3 px-7 mt-20 rounded-full text-white"
          href="/hr/kontakt"
        >
          Kupi kartu
        </a>
      </div>
    </section>
  );
};

export default Avio;
