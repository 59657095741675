import rentImage from "../../assets/img/vela.jpg";
const PriceBike = () => {
  return (
    <section className="price container mx-auto px-4">
      <h2 className="main-title py-4">Uključeno u cijenu</h2>
      <div className="price-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
        <div className="price-selection">
          <ul className="gap-5 flex flex-col">
            <li>Klasično osiguranje</li>
            <li>2 kacige</li>
            <li>Vozač i suvozač</li>
            <li>Neograničeno kilometara</li>
            <li>Besplatan parking u Veloj Luci</li>
            <li>
              Besplatna dostava/preuzimanje vozila na području Vele Luke
            </li>
          </ul>
        </div>
        <img className="rounded-xl" src={rentImage} alt="" />
      </div>
    </section>
  );
};

export default PriceBike;
