import Navbar from "../components/rents/Navbar";
import realEstateImage from "../assets/img/real-estate.jpg";
import Contact from "../components/rents/Contact";
import Footer from "../components/Footer";

const option = [
  {
    option: "Buy Real Estate",
    value: "Buy Real Estate",
  },
  {
    option: "Sell Real Estate",
    value: "Sell Real Estate",
  },
];
const HrRealEstate = () => {
  return (
    <>
      <Navbar />
      <section className="container mx-auto py-10 px-4 apartment">
        <h1 className="bigTitle">Kupite ili prodajte svoju nekretninu.</h1>
        <div className="apartment-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div>
            <p className="pb-20">
              Ukoliko želite ponuditi svoju nekretninu na prodaju ili tražite
              nešto prikladno za kupnju, kontaktirajte nas za više
              informacija.
            </p>
            <Contact option={option} />
          </div>
          <img
            className="w-full rounded-xl"
            src={realEstateImage}
            alt="Buy or sell your real estate with our help"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default HrRealEstate;
