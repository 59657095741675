import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "../components/rents/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/rents/Contact";
import cafo from "../assets/img/cafo2.webp";

// Icons import

import ac from "../assets/img/icons-apart/ac.png";
import tree from "../assets/img/icons-apart/tree.png";
import bath from "../assets/img/icons-apart/bath.png";
import bed from "../assets/img/icons-apart/bed.png";
import cat from "../assets/img/icons-apart/cat.png";
import dishwasher from "../assets/img/icons-apart/dishwasher.png";
import fireplace from "../assets/img/icons-apart/fireplace.png";
import kitchen from "../assets/img/icons-apart/kitchen.png";
import sun from "../assets/img/icons-apart/sun.png";
import tv from "../assets/img/icons-apart/tv.png";
import weber from "../assets/img/icons-apart/weber.png";
import wifi from "../assets/img/icons-apart/wifi.png";
import surface from "../assets/img/icons-apart/surface.png";
import parking from "../assets/img/icons-apart/parking.png";
import yoga from "../assets/img/icons-apart/yoga.png";

import hh1 from "../assets/img/hh2/1.jpg";
import hh2 from "../assets/img/hh2/2.jpg";
import hh3 from "../assets/img/hh2/3.jpg";
import hh4 from "../assets/img/hh2/4.jpg";
import hh5 from "../assets/img/hh2/5.jpg";
import hh6 from "../assets/img/hh2/6.jpg";
import hh7 from "../assets/img/hh2/7.jpg";
import hh8 from "../assets/img/hh2/8.jpg";
import hh9 from "../assets/img/hh2/9.jpg";
import hh10 from "../assets/img/hh2/10.jpg";

// Image imports
import left from "../assets/img/icons/left.svg";
import right from "../assets/img/icons/right.svg";

const HrCafo = () => {
  const option = [
    {
      option: "Select",
      value: "Select",
    },
    {
      option: "Apartmant Cafo",
      value: "Apartman Cafo",
    },
  ];

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // Remove controls
    dots: false,
    arrows: false,
    // Remove dots
    // Remove autoplay
    autoplay: false,
  };

  const ref2 = useRef<Slider | null>(null);

  const handleNextSlide2 = () => {
    if (ref2.current) {
      ref2.current.slickNext?.();
    }
  };

  const handlePrevSlide2 = () => {
    if (ref2.current) {
      ref2.current.slickPrev?.();
    }
  };

  return (
    <>
      <Navbar />
      <section className="container mx-auto px-4">
        <div className=" gap-10 flex flex-col lg:flex-row items-center py-12">
          <img src={cafo} alt="Čafo apartment main" />
          <div className="left-section">
            <h1 className="third-title text-2xl md:text-3xl lg:text-6xl py-5">
              Čafo Apartman
            </h1>
            <p className="mb-3">
              Predivna kuća za odmor na izvrsnoj lokaciji. Nalazi se u uvali
              Pelegrin, 6 km od centra Vela Luke, te samo 100 m od mora. Ima 2
              spavaće sobe i ima kapacitet za 5 osoba. Kuća ima prekrasnu terasu
              s prekrasnim pogledom na more.
            </p>
            <span className="text-oceanBlue font-extrabold mt-3">
              Lokacija: Pelegrin 19
            </span>
          </div>
        </div>
        <div className="equipment">
          <h2 className="third-title text-2xl md:text-3xl lg:text-6xl py-5 text-center">
            Naš apartman sadrži
          </h2>
          <div className="flex flex-col md:flex-row  justify-around py-40">
            <div className="eqp flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <img src={tree} alt="Tree" />
                <h4>Privatni vrt</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={bed} alt="Tree" />
                <h4>2 sobe - 5 osoba</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={weber} alt="Tree" />
                <h4>Roštilj</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={wifi} alt="Tree" />
                <h4>Wi Fi</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={bath} alt="Tree" />
                <h4>Kupaonica</h4>
              </div>
            </div>
            <div className="eqp flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <img src={fireplace} alt="Tree" />
                <h4>Kamin</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={dishwasher} alt="Tree" />
                <h4>Perilica suđa</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={kitchen} alt="Tree" />
                <h4>Kuhinjske jedinice</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={ac} alt="Tree" />
                <h4>Klima</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={cat} alt="Tree" />
                <h4>Ljubimci nisu dozovljeni</h4>
              </div>
            </div>
            <div className="eqp flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <img src={parking} alt="Tree" />
                <h4>Privatni parking - 3 auta</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={tv} alt="Tree" />
                <h4>TV</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={sun} alt="Tree" />
                <h4>Najbolji pogled na sunce</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={surface} alt="Tree" />
                <h4>Površina 100m2</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={yoga} alt="Tree" />
                <h4>Najbolje opuštanje</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="gallery">
          <h2 className="third-title text-2xl md:text-3xl lg:text-6xl py-5 text-center">
            Slike apartmana
          </h2>
          <Slider {...settings2} ref={ref2} className="hh">
            <img src={hh1} alt="" />
            <img src={hh2} alt="" />
            <img src={hh3} alt="" />
            <img src={hh4} alt="" />
            <img src={hh5} alt="" />
            <img src={hh6} alt="" />
            <img src={hh7} alt="" />
            <img src={hh8} alt="" />
            <img src={hh9} alt="" />
            <img src={hh10} alt="" />
          </Slider>
          <div className="buttons flex items-center w-max gap-3 py-5 m-auto">
            <button onClick={handlePrevSlide2}>
              <img src={left} alt="Left Arrow" />
            </button>
            <button onClick={handleNextSlide2}>
              <img src={right} alt="Right Arrow" />
            </button>
          </div>
        </div>
        <Contact option={option} />
        <div className="my-10"></div>
      </section>
      <Footer />
    </>
  );
};

export default HrCafo;
