import Navbar from "../components/rents/Navbar";
import apartmentImage from "../assets/img/apartments.jpg";
import Contact from "../components/rents/Contact";
import Footer from "../components/Footer";
import Houses from "../components/accommodation/Houses";

const option = [
  {
    option: "Gige Apartment",
    value: "Gige Apartment",
  },
  {
    option: "Čafo Apartment",
    value: "Čafo Apartment",
  },
];

const Accommodation = () => {
  return (
    <>
      <header className="apartmanBg text-white relative">
        <Navbar />
        <div className="wrapper container mx-auto px-4 absolute bottom-4 left-4">
          <h1 className="bigTitle">Apartments and holiday houses for rent.</h1>
          <p>
            Private apartments are the ideal vacation choice for anyone who
            wants peace and intimacy.
          </p>
          <p className="pb-10">
            Take a look at our offer of private apartments and holiday homes in
            Vela Luka and the surrounding area!
          </p>
        </div>
      </header>
      <section className="container mx-auto py-10 px-4 apartment">
        <Houses />
        {/* <h4>Damage and incidentals?</h4>
        <p>
          You will be responsible for any damage to the rental property caused
          by you or your party during your stay.
        </p> */}

        <div className="apartment-wrapper py-10 grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div>
            <Contact option={option} />
          </div>
          <img
            className="w-full rounded-xl"
            src={apartmentImage}
            alt="Renta an apartment or holiday house in Korčula"
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Accommodation;
