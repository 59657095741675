import React, { useState } from "react";
import logo from "../../assets/img/logo.png";
import { motion } from "framer-motion";
interface Faq {
  question: string;
  answer: string;
}

interface Props {
  faqs: Faq[];
  faqs2: Faq[];
}

const faqs = [
  {
    question: "Rents",
    answer: "Car rental",
    answer2: "Scooter rental",
    answer3: "Bicycle rental",
    answer4: "Boat rental",
  },
];
const faqs2 = [
  {
    question: "Insurance",
    answer: "Insurance",
    answer2: "Travel Insurance",
  }
]

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [activeIndex2, setActiveIndex2] = useState<number | null>(null);
  const handleClick = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const handleClick2 = (index: number) => {
    setActiveIndex2(activeIndex2 === index ? null : index);
  };

  return (
    <nav className="container mx-auto px-4 pt-5 flex items-center justify-between">
      <div className="logo">
        <a href="/">
          <img src={logo} alt="Atlas Vela Luka Touristic Agency Logo" />
        </a>
      </div>
      <ul className="as links flex items-center gap-5 text-xl">
        <li>
          <a href={"/"}>Home</a>
        </li>
        <div className="dropdown">
          <button className="dropbtn">Rents</button>
          <div className="dropdown-content">
            <a href={"/car-rental"}>Renta Car</a>
            <a href={"/scooter-rental"}>Renta Scooter</a>
            <a href={"/boat-rental"}>Renta Boat</a>
            <a href={"/bicycle-rental"}>Renta Bicycle</a>
          </div>
        </div>
        <li>
          <a href={"/tickets"}>Tickets</a>
        </li>
        <div className="dropdown">
          <button className="dropbtn">Insurance</button>
          <div className="dropdown-content">
            <a href={"/insurance/"}>Insurance</a>
            <a href={"/insurance/travel-insurance"}>Travel Insurance</a>
          </div>
        </div>
        <li>
          <a href={"/excursion"}>Excursion</a>
        </li>
        <li>
          <a href={"/accommodation"}>Accommodation</a>
        </li>
        <li>
          <a href={"/real-estate"}>Real Estate</a>
        </li>
        <li>
          <a href={"/fish-licence"}>Fish Licence</a>
        </li>
        <li className="bg-oceanBlue py-3 px-7 rounded-full">
          <a className="text-white" href={"/hr/"}>
            HR
          </a>
        </li>
        <li className="bg-oceanBlue py-3 px-7 rounded-full">
          <a className="text-white" href={"/contact"}>
            Contact
          </a>
        </li>
      </ul>
      <div className="overlay" style={{width: isOpen ? "100%" : "0%"}}>
        <ul>
          <li>
            <a href={"/"}>Home</a>
          </li>
          <li>
            <a href={"/tickets"}>Tickets</a>
          </li>
          {faqs2.map((faq, index) => (
              <div className="text-center text-xl" key={faq.question}>
                <motion.h3
                    className={`question ${
                        activeIndex2 === index ? "active" : ""
                    }`}
                    onClick={() => handleClick2(index)}
                    animate={{
                      transition: { duration: 0.3, ease: "easeInOut" },
                    }}
                >
                  <li className="flex gap-3">{faq.question}</li>
                </motion.h3>
                <motion.div
                    className={`answer ${
                        activeIndex2 === index ? "active" : ""
                    }`}
                    initial={{ maxHeight: 0, opacity: 0 }}
                    animate={{
                      maxHeight: activeIndex2 === index ? 1000 : 0,
                      opacity: activeIndex2 === index ? 1 : 0,
                      transition: { duration: 0.3, ease: "easeInOut" },
                    }}
                >
                  <li>
                    <a href="/insurance">{faq.answer}</a>
                  </li>
                  <li>
                    <a href="/insurance/travel-insurance">{faq.answer2}</a>
                  </li>
                </motion.div>
              </div>
          ))}
          <li className={"z-50"}>
            <a href={"/excursion"}>Excursion</a>
          </li>
          {faqs.map((faq, index) => (
            <div className="text-center text-xl" key={faq.question}>
              <motion.h3
                className={`question ${activeIndex === index ? "active" : ""}`}
                onClick={() => handleClick(index)}
                animate={{
                  transition: { duration: 0.3, ease: "easeInOut" },
                }}
              >
                <li className="flex gap-3">{faq.question}</li>
              </motion.h3>
              <motion.div
                className={`answer ${activeIndex === index ? "active" : ""}`}
                initial={{ maxHeight: 0, opacity: 0 }}
                animate={{
                  maxHeight: activeIndex === index ? 1000 : 0,
                  opacity: activeIndex === index ? 1 : 0,
                  transition: { duration: 0.3, ease: "easeInOut" },
                }}
              >
                <li>
                  <a href="/car-rental">{faq.answer}</a>
                </li>
                <li>
                  <a href={"/scooter-rental"}>{faq.answer2}</a>
                </li>
                <li>
                  <a href={"/bicycle-rental"}>{faq.answer3}</a>
                </li>
                <li>
                  <a href={"/boat-rental"}>{faq.answer4}</a>
                </li>
              </motion.div>
            </div>
          ))}
          <li className="z-50">
            <a href={"/accommodation"}>Accommodation</a>
          </li>
          <li className="z-50">
            <a href={"/real-estate"}>Real Estate</a>
          </li>
          <li className="z-50">
            <a href={"/fish-licence"}>Fish licence</a>
          </li>
          <li className="bg-oceanBlue py-3 px-7 rounded-full">
            <a className="text-white" href={"/hr/"}>
              HR
            </a>
          </li>
          <li className="bg-oceanBlue py-3 px-7 rounded-full">
            <a className="text-white" href={"/contact"}>
              Contact
            </a>
          </li>
        </ul>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <svg
          className={
            isOpen ? "ham hamRotate180 ham5 active" : "ham hamRotate180 ham5"
          }
          viewBox="0 0 100 100"
          width="80"
        >
          <path
            className="line top"
            d="m 30,33 h 40 c 0,0 8.5,-0.68551 8.5,10.375 0,8.292653 -6.122707,9.002293 -8.5,6.625 l -11.071429,-11.071429"
          />
          <path className="line middle" d="m 70,50 h -40" />
          <path
            className="line bottom"
            d="m 30,67 h 40 c 0,0 8.5,0.68551 8.5,-10.375 0,-8.292653 -6.122707,-9.002293 -8.5,-6.625 l -11.071429,11.071429"
          />
        </svg>
      </div>
    </nav>
  );
};

export default Navbar;
