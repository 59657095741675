import React from "react";
import rentImage from "../../assets/img/vela.jpg";
const Price = () => {
  return (
    <>
      <section className="price container mx-auto px-4">
        <h2 className="main-title py-4">Uključeno u cijenu</h2>
        <div className="price-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
          <div className="price-selection">
            <ul className="gap-5 flex flex-col">
              <li>Besplatan parking u Veloj Luci</li>
              <li>Neograničeno kilometara</li>
              <li>Osiguranje vozila</li>
              <li>Porez</li>
              <li>Besplatna dostava u Veloj Luci</li>
              <li>Besplatna dječja sjedalica</li>
              <li>Pomoć na cesti</li>
              <li>Odricanje od štete u slučaju sudara</li>
              <li>Osobno osiguranje od nezgode za vozače i putnike</li>
            </ul>
          </div>
          <img className="rounded-xl" src={rentImage} alt="" />
        </div>
      </section>
    </>
  );
};

export default Price;
