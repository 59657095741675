import Footer from "../components/Footer";
import Contact from "../components/rents/Contact";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Price from "../components/rents/Price";
import Rents from "../components/rents/Rents";

const faqs = [
  {
    question: "Which documents I need to rent a car?",
    answer:
      "When signing the contract, a valid driver's license must be attached to the passport or identity card.",
  },
  {
    question: "What is the minimum driver age?",
    answer: "The minimum age of the driver is 21 years.",
  },
  {
    question: "What is the minimum rental period?",
    answer: "The minimum rental period is 12 hours.",
  },
  {
    question: "Is fuel included in the price?",
    answer:
      "Fuel is not included in the price. The vehicle should be returned with the same amount of fuel that you found when you picked it up.",
  },
  {
    question: "Can reservation be changed or cancelled?",
    answer:
      "A reservation must do so in writing (via e-mail or GSM). Changes constitute a change in the renters name or the date of the commencement and/or end date of the service. If the renter cancels a reservation up to 14 days before the designated pick-up time a 15,00 € cancellation fee will be charged. If the renter cancels a reservation within 14 days before the designated pick-up time, does not pick up the rental car or cancels reservation after the designated pick-up time a 20% cancellation fee of the total amount of the reservation will be charged.",
  },
  {
    question: "What insurance is included in the price?",
    answer:
      "Liability insurance for damage caused to a third party. CDW (Collision Damage Waiver) - risk insurance with participation in damages. The user's liability for damage and theft is limited to the amount of the deductible/participation in damage.",
  },
  {
    question: "Cleanliness of vehicles",
    answer:
      "In the event that the vehicle requires special cleaning procedures (e.g. removal of odors, animal hair, liquid spills) upon return, a minimum fee will be charged.",
  },
  {
    question: "What is a franchise?",
    answer: `
    The deductible is the maximum amount of participation in damages. The amount of participation in the damage depends on the vehicle category.
    <br/>
    <br/>
    Is payment made when picking up or returning the vehicle?
    
    <br/>
    <br/>
     
    When picking up the vehicle, a credit card is mandatory as a guarantee of the rental payment, on which a deposit is taken, and the rental is always charged when the vehicle is returned.
    
    <br/>
    <br/>
    How much deposit do I need on my credit card?
    
    <br/>
    <br/>
     
    When picking up the vehicle, a deposit will be taken on the credit card. The deposit depends on the vehicle category and the insurance option that the customer chooses when renting the vehicle.
    
    <br/>
    <br/>
    What is the purpose of a deposit on a credit card?
    
    <br/>
    <br/>
     
    The deposit on the credit card is used to collect the agreed rent, possible damages, extension of the rent, subsequent taking of additional services, parking fines, fines for speeding or the like and for all other fees arising from the vehicle rental.
    
    <br/>
    <br/>
    When will the pre-authorization on my credit card be canceled?
    
    <br/>
    <br/>
     
    If the payment at the end of the rental is made with the same card on which the pre-authorization was carried out, the rest of the funds should be unblocked within a few days. In case of payment by cash or another credit card, funds may be blocked for up to thirty days, depending on the bank.
    
    <br/>
    <br/>
    We cannot influence the period for which the pre-authorized amount will be unblocked because it depends on the credit card issuing bank, not us or our bank.
    
    <br/>
    <br/>
    Is it possible to pick up a vehicle in one city and leave it in another?
    
    <br/>
    <br/>
     
    It is possible to pick up the vehicle in one city and return it in another city. We call this service "one way" and it is charged according to the tariff, unless one of the promotions is valid, where the fee for one-way rental is included in the rent.
    
    <br/>
    <br/>
    
    Can more than one person drive a rental car?
    
    <br/>
    <br/>
     
    Multiple persons can be placed on the rental agreement as drivers. When picking up the vehicle, all drivers must show their driver's license and meet the rental conditions.
    
    <br/>
    <br/>
    What is the procedure in case of damage/accident to the vehicle?
    
    <br/>
    <br/>
     
    If you have any damage to the vehicle, it is necessary to contact the branch where the vehicle was taken over and the police in order to make a police report so that the agreed conditions apply.
    
    <br/>
    <br/>
    Do I need to inspect the vehicle when renting it?
    
    <br/>
    <br/>
     
    Yes, it is mandatory to inspect the vehicle upon collection and compare the damage on the vehicle with the damage on the check out form that you received with the rental agreement.
    
    <br/>
    <br/>
    If there is damage to the vehicle that is not listed on the check out form, contact your colleagues to mark the damage and avoid problems and charges for damages when returning the vehicle.`,
  },
];
const option = [
  {
    option: "Chevrolet Lacetti",
    value: "Chevrolet Lacetti",
  },
  {
    option: "Chevrolet Aveo 1.4",
    value: "Chevrolet Aveo 1.4",
  },
  {
    option: "Chevrolet Aveo 1.2",
    value: "Chevrolet Aveo 1.2",
  },
  {
    option: "VW UP",
    value: "VW UP",
  },
];

const rentalCar = () => {
  return (
    <>
      <Navbar />
      <Rents />
      <Price />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default rentalCar;
