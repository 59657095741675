import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import gige from "../../assets/img/gige.webp";
import cafo from "../../assets/img/cafo.webp";
import vranac from "../../assets/img/vranac.webp";

const Houses = () => {
  return (
    <>
      <div>
        <h3 className="third-title text-2xl md:text-3xl lg:text-6xl">
          Naši apartmani
        </h3>
        <p className="my-5">
          Nudimo Vam putno osiguranje kao i osiguranje od otkaza putovanja -{" "}
          <a className="text-oceanBlue" href="/insurance">
            Croatia osiguranje
          </a>
          , vodeće osiguravajuće društvo u Hrvatskoj.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between gap-10 py-10">
          <div className="gige apart flex flex-col items-center text-center justify-between">
            <img className="" src={gige} alt="Gige Apartments first look" />
            <h4>Gige Apartman</h4>
            <p>
              Predivna kuća za odmor na izvrsnoj lokaciji. Nalazi se u uvali
              Pelegrin, 7 km od centra Vela Luke.
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/hr/smjestaj/gige-apartman"
            >
              Vidi Više
            </a>
          </div>
          <div className="cafo apart flex flex-col items-center text-center justify-between">
            <img className="" src={cafo} alt="Čafo Apartments first look" />
            <h4>Čafo Apartman</h4>
            <p>
              Predivna kuća za odmor na izvrsnoj lokaciji. Nalazi se u uvali
              Pelegrin, 6 km od centra Vela Luke, te samo 100 m od mora.
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/hr/smjestaj/cafo-apartman"
            >
              Vidi Više
            </a>
          </div>
          <div className="gige apart flex flex-col items-center text-center justify-between">
            <img className="" src={vranac} alt="Gige Apartments first look" />
            <h4>Apartman Vranac</h4>
            <p>
              pruža Vam sve što Vam je potrebno za savršen odmor u dvoje. 30 m2
              unutarnjeg prostora i 11 m2 natkrivene terase desno...
            </p>
            <a
              className="bg-oceanBlue mt-5 rounded-full text-white px-6 py-2"
              href="/hr/smjestaj/vranac-apartman"
            >
              Vidi više
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Houses;
