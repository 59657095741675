import Header from "../components/Header";
import Category from "../components/Category";
import Destination from "../components/Destination";
import Who from "../components/Who";
import Journey from "../components/Journey";
import Footer from "../components/Footer";
import FindUs from "../components/FindUs";
import React from "react";

export default function HrHome() {
  return (
    <>
      <Header />
      <Category />
      <Destination />
      <FindUs />
      <Who />
      <Journey />
      <Footer />
    </>
  );
}
