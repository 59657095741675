import React, { useEffect } from "react";

const HrNotFound = () => {
  return (
    <div className="text-center flex items-center flex-col m-auto">
      <div>NotFound</div>
      <p>The page you looking doesnt exist</p>
      <a className="text-oceanBlue underline" href="https://atlas.com.hr">
        Go to the new website
      </a>
    </div>
  );
};

export default HrNotFound;
