import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import emailjs from "emailjs-com";
type Name = {
  name: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
};

type Email = {
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};
type Message = {
  message: string;
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

type Submit = {
  submitted: boolean;
  setSubmitted: React.Dispatch<React.SetStateAction<boolean>>;
};

const Contact = ({ option }: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [date, setDate] = useState("");
  const [selectedVehicle, setSelectedVehicle] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [formIncomplete, setFormIncomplete] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const sentEmail = (e: any) => {
    e.preventDefault();
    if (!name || !email || !date || !selectedVehicle || !message) {
      setFormIncomplete(true);
      return;
    } else {
      emailjs
        .sendForm(
          "service_fmur94p",
          "template_ni6kwee",
          e.target,
          "kgJQR2LDSJhNf_MqI"
        )
        .then(
          (result) => {
            console.log(result.text);
            setFormSubmitted(true);
            setName("");
            setDate("");
            setSelectedVehicle("");
            setEmail("");
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div className="contact-section">
      <Snackbar
        open={formSubmitted}
        autoHideDuration={6000}
        onClose={() => setFormSubmitted(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          top: "50px",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <Alert severity="success">Uspješno poslano!</Alert>
      </Snackbar>
      <Snackbar
        open={formIncomplete}
        autoHideDuration={6000}
        onClose={() => setFormIncomplete(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{
          top: "50px",
          left: "50%",
          transform: "translate(-50%, 0)",
        }}
      >
        <Alert severity="warning">Molim vas ispunite sva polja.</Alert>
      </Snackbar>
      <h2 className="main-title">Kontaktirajte za rezervaciju</h2>
      <form onSubmit={sentEmail} className="flex flex-col gap-5">
        <input
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          type="text"
          placeholder="Ime"
          name="name"
        />
        <input
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          value={email}
          type="email"
          placeholder="Email"
          name="email"
        />
        <input
          onChange={(e) => {
            setDate(e.target.value);
          }}
          value={date}
          type="date"
          placeholder="Datum"
          name="date"
        />
        <label htmlFor="cars">Odaberi opciju</label>
        <select
          name="cars"
          id=""
          value={selectedVehicle}
          onChange={(e) => setSelectedVehicle(e.target.value)}
        >
          {option.map((el: any, key: any) => (
            <option key={key} value={el.value}>
              {el.option}
            </option>
          ))}
        </select>
        <textarea
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
          name="message"
          placeholder="Poruka"
          id="textarea"
          cols={15}
          rows={10}
        />
        <p>
          Slanjem poruke prihvaćate{" "}
          <a className="text-oceanBlue underline" href="/terms-and-conditions">
            Odredbe i Uvjete
          </a>{" "}
          I{" "}
          <a className="text-oceanBlue underline" href="/policy-and-privacy">
            Politiku privatnosti
          </a>{" "}
          naše web stranice.
        </p>
        <input
          className="submit-btn contact-button"
          type="submit"
          name="submit"
          placeholder="Submit"
        />
      </form>
    </div>
  );
};

export default Contact;
function setBody(arg0: string) {
  throw new Error("Function not implemented.");
}
