import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Navbar from "../components/rents/Navbar";
import Footer from "../components/Footer";
import Contact from "../components/rents/Contact";
import vranac from "../assets/img/vranac2.webp";

// Icons import

import ac from "../assets/img/icons-apart/ac.png";
import tree from "../assets/img/icons-apart/tree.png";
import bath from "../assets/img/icons-apart/bath.png";
import bed from "../assets/img/icons-apart/bed.png";
import cat from "../assets/img/icons-apart/cat.png";
import dishwasher from "../assets/img/icons-apart/dishwasher.png";
import fireplace from "../assets/img/icons-apart/fireplace.png";
import kitchen from "../assets/img/icons-apart/kitchen.png";
import sun from "../assets/img/icons-apart/sun.png";
import tv from "../assets/img/icons-apart/tv.png";
import weber from "../assets/img/icons-apart/weber.png";
import wifi from "../assets/img/icons-apart/wifi.png";
import surface from "../assets/img/icons-apart/surface.png";
import parking from "../assets/img/icons-apart/parking.png";
import yoga from "../assets/img/icons-apart/yoga.png";

import h1 from "../assets/img/vranac/1.webp";
import h2 from "../assets/img/vranac/2.webp";
import h3 from "../assets/img/vranac/3.webp";
import h4 from "../assets/img/vranac/4.webp";
import h5 from "../assets/img/vranac/5.webp";
import h6 from "../assets/img/vranac/6.webp";
import h7 from "../assets/img/vranac/7.webp";
import h8 from "../assets/img/vranac/8.webp";
import h10 from "../assets/img/vranac/10.webp";

// Image imports
import left from "../assets/img/icons/left.svg";
import right from "../assets/img/icons/right.svg";

const HrVranac = () => {
  const option = [
    {
      option: "Select",
      value: "Select",
    },
    {
      option: "Apartmant Vranac",
      value: "Apartman Vranac",
    },
  ];

  const settings2 = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // Remove controls
    dots: false,
    arrows: false,
    // Remove dots
    // Remove autoplay
    autoplay: false,
  };

  const ref2 = useRef<Slider | null>(null);

  const handleNextSlide2 = () => {
    if (ref2.current) {
      ref2.current.slickNext?.();
    }
  };

  const handlePrevSlide2 = () => {
    if (ref2.current) {
      ref2.current.slickPrev?.();
    }
  };

  return (
    <>
      <Navbar />
      <section className="container mx-auto px-4">
        <div className=" gap-10 flex flex-col lg:flex-row items-center py-12">
          <div className="left-section">
            <h1 className="third-title text-2xl md:text-3xl lg:text-6xl py-5">
              Vranac Apartment
            </h1>
            <p className="mb-3">
              Apartman Vranac Vam pruža sve što Vam je potrebno za savršen odmor
              u dvoje. 30 m2 unutarnjeg prostora i 11 m2 natkrivene terase tik
              uz more s pogledom na Velu Luku. Nalazi se na predjelu Vranac u
              obiteljskoj kući, 1 km od centra mjesta, 2 minute autom ili 10
              minuta hoda uz more. Susjedstvo je mirno. Javna plaža Posejdon
              udaljena je samo 500 m, gdje imate beach bar, najbliži restoran je
              udaljen 100 m, a trgovina je udaljena 500 m. Apartman ima vlastito
              privatno parkiralište, što olakšava ako imate automobil.
            </p>
            <span className="text-white bg-oceanBlue px-6 py-2 rounded-full font-extrabold mt-3">
              <a href="https://maps.app.goo.gl/6runASJzzaD5wJir6">
                Obala 1 9, 20270, Vela Luka
              </a>
            </span>
          </div>
          <img
            className="rounded-xl"
            src={vranac}
            alt="Vranac apartment main"
          />
        </div>
        <div className="equipment">
          <h2 className="third-title text-2xl md:text-3xl lg:text-6xl py-5 text-center">
            Naš apartman sadrži
          </h2>
          <div className="flex flex-col md:flex-row  justify-around py-40">
            <div className="eqp flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <img src={tree} alt="Tree" />
                <h4>Teraca</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={bed} alt="Tree" />
                <h4>2 sobe - 4 osobe</h4>
              </div>
              {/* <div className="flex items-center gap-5">
                <img src={weber} alt="Tree" />
                <h4>Roštilj</h4>
              </div> */}
              <div className="flex items-center gap-5">
                <img src={wifi} alt="Tree" />
                <h4>Wi Fi</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={bath} alt="Tree" />
                <h4>Kupaonicu</h4>
              </div>
            </div>
            <div className="eqp flex flex-col gap-5">
              {/* <div className="flex items-center gap-5">
                <img src={fireplace} alt="Tree" />
                <h4>Kamin</h4>
              </div> */}
              <div className="flex items-center gap-5">
                <img src={dishwasher} alt="Tree" />
                <h4>Perilica suđa</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={kitchen} alt="Tree" />
                <h4>Kuhinjske jedinice</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={ac} alt="Tree" />
                <h4>Klima</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={cat} alt="Tree" />
                <h4>Ljubimci nisu dopušteni</h4>
              </div>
            </div>
            <div className="eqp flex flex-col gap-5">
              <div className="flex items-center gap-5">
                <img src={parking} alt="Tree" />
                <h4>Privatan parking + vez za brod</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={tv} alt="Tree" />
                <h4>TV</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={sun} alt="Tree" />
                <h4>Najbolji pogled na sunce</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={surface} alt="Tree" />
                <h4>Površina 30m2 + 11m2</h4>
              </div>
              <div className="flex items-center gap-5">
                <img src={yoga} alt="Tree" />
                <h4>Najbolje opuštanje</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="gallery">
          <h2 className="third-title text-2xl md:text-3xl lg:text-6xl py-5 text-center">
            Slike apartmana
          </h2>
          <Slider {...settings2} ref={ref2} className="hh">
            <img src={h1} alt="" />
            <img src={h2} alt="" />
            <img src={h3} alt="" />
            <img src={h4} alt="" />
            <img src={h5} alt="" />
            <img src={h6} alt="" />
            <img src={h7} alt="" />
            <img src={h8} alt="" />
            <img src={h10} alt="" />
          </Slider>
          <div className="buttons flex items-center w-max gap-3 py-5 m-auto">
            <button onClick={handlePrevSlide2}>
              <img src={left} alt="Left Arrow" />
            </button>
            <button onClick={handleNextSlide2}>
              <img src={right} alt="Right Arrow" />
            </button>
          </div>
        </div>
        <Contact option={option} />
        <div className="my-10"></div>
      </section>
      <Footer />
    </>
  );
};

export default HrVranac;
