const Journey = () => {
  return (
    <>
      <section className="journey-wrapper bg-babyBlue rounded-xl w-full text-center p-10 mt-20 mb-20">
        <h2>Jeste li spremni za avanturu na sljedećoj razini?</h2>
        <p className="mt-5 mb-10">
        Dođite na otok ljepote s najboljim pristupačnim automobilima, skuterima, čamcima i
        istražite svaki pedalj otoka.
        </p>
        <a
          className="bg-oceanBlue px-5 py-3 rounded-xl text-white"
          href="/hr/kontakt"
        >
          Kreni u svoju avanturu
        </a>
      </section>
    </>
  );
};

export default Journey;
