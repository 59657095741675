import korcula from "../assets/img/korcula.jpg";
const Who = () => {
  return (
    <section className="container mx-auto px-4">
      <h2 className="main-title text-oceanBlue">Tko smo mi?</h2>
      <div className="who-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center py-5 gap-5">
        <img
          src={korcula}
          className="rounded-xl"
          alt="Korčula Island City Center"
        />
        <div className="about-us-wrapper">
          <h3>Otok Korčula - Vela Luka</h3>
          <div className="texts">
            <p>
              Osim što nudi odmor, opuštanje i uživanje, otok Korčula nudi i
              povratak prirodi, uključivanje svih tjelesnih osjetila koja su u
              gradovima isključena i beskorisna.
            </p>
            <p>
              Osnovna djelatnost, uz kupanje u moru i sunčanje, može i kušanje
              raznih domaćih specijaliteta, namirnica prikupljenih u divljini i
              pripremljeni prema tradicionalnoj recepturi, bez kemikalija.
            </p>
            <p className="mb-10">
              Unošenjem čistih eliksira života tijelo se budi i postaje zdravo,
              a poznata izreka &quot;ono si što jedeš&quot; višestruko će se
              dokazati. Kako bismo obišli sve ljepote Korčule kopnom i morem
              nudimo mogućnost najma vozila koji najbolje odgovaraju vašim
              potrebama i mogućnostima.
            </p>
            <a
              className="bg-oceanBlue py-3 px-7 rounded-full text-white"
              href="/hr/kontakt"
            >
              Upitajte nas !
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Who;
