import rentImage from "../../assets/img/vela.jpg";
const PriceBic = () => {
  return (
    <section className="price container mx-auto px-4">
      <h2 className="main-title py-4">Uključeno u cijenu</h2>
      <div className="price-wrapper grid md:grid-cols-1 lg:grid-cols-2 items-center gap-5">
        <div className="price-selection">
          <ul className="gap-5 flex flex-col">
            <li>Lokot za zaštitu od krađe</li>
            <li>Torba za alat</li>
            <li>Sjedalo za djecu</li>
            <li>
              Vršimo dostavu na željenu lokaciju na području Vele Luke.
            </li>
            <li>Odobravamo popust na grupni i obiteljski najam!</li>
          </ul>
        </div>
        <img className="rounded-xl" src={rentImage} alt="" />
      </div>
    </section>
  );
};

export default PriceBic;
