import Footer from "../components/Footer";
import Boat from "../components/rents/Boat";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Contact from "../components/rents/Contact";
import PriceBoat from "../components/rents/PriceBoat";
const faqs = [
  {
    question: "Što ako otkažem svoju rezervaciju?",
    answer:
      "U slučaju otkazivanja rezervacije najma automobila ili skutera, zadržavamo uplaćenu akontaciju za potvrdu rezervacije.",
  },
  {
    question: "Je li gorivo uključeno u cijenu?",
    answer:
      "Gorivo nije uključeno u cijenu najma vozila, a prilikom povratka vozilo je potrebno vratiti s istom količinom goriva u kojoj je i preuzeto.",
  },
  {
    question: "Jesu li uključene skije ili balon za vuču?",
    answer: "Skije ili vučni balon po želji",
  },
  {
    question: "Koja je oprema uključena u najam plovila?",
    answer:
      "Svi ponuđeni brodovi za iznajmljivanje imaju svu potrebnu opremu za siguran i ugodan boravak na moru.",
  },
  {
    question: "Dolazi li uz brod osiguranje?",
    answer:
      "Da, svako plovilo dolazi s osiguranjem, ali s franšizom koja ovisi o vrsti plovila. Sudjelovanje franšize u naknadi štete umanjuje odgovornost najmoprimca za štetu. Ako je trošak štete manji od vrijednosti franšize, najmoprimac mora platiti manji iznos.",
  },
  {
    question: "Da li je kod najma čamca potreban depozit?",
    answer:
      "Da, prilikom iznajmljivanja plovila potreban je povratni depozit. Polog može biti samo u gotovini i/ili predautoriziran na kreditnoj kartici. Polog će biti vraćen ako se plovilo vrati neoštećeno. Ne nudimo osiguranje depozita.",
  },
  {
    question: "Što nije pokriveno osiguranjem?",
    answer: `Sljedeći rizici nisu pokriveni osiguranjem najma plovila:
    <br>
    - Rizici za koje iznajmljivač plovila ima osiguranu imovinu, a za koje u ugovoru nije definirana franšiza.
    <br>
    - Namjerno oštećenje.
    <br>
    - Šteta uzrokovana neadekvatnim upravljanjem, neispravnom opremom ili zato što osigurano plovilo nije bilo u uvjetima pogodnim za plovidbu.
    <br>
    - Šteta uzrokovana izgradnjom, proizvodnjom ili materijalna šteta.`,
  },
];
const option = [
  {
    option: "Fisherman BlueMax 550",
    value: "Fisherman BlueMax 550",
  },
  {
    option: "Nautica 500",
    value: "Nautica 500",
  },
  {
    option: "Molinari",
    value: "Molinari",
  },
  {
    option: "Sessa Key Largo",
    value: "Sessa Key Largo",
  },
];
const HrBoatRental = () => {
  return (
    <>
      <Navbar />
      <Boat />
      <PriceBoat />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default HrBoatRental;
