import scooter from "../../assets/img/fly.png";
import scooter2 from "../../assets/img/scooter21.png";
import scooter3 from "../../assets/img/honda.png";
import scooter4 from "../../assets/img/nmax.png";

interface Scooters {
  id: number;
  title: string;
  spec: string;
  image: {
    src: any;
    alt: string;
  };
  stars: number;
}

const scooters: Scooters[] = [
  {
    id: 1,
    title: "Scooter 50cm3",
    spec: "Fuel Eurosuper 95",
    image: {
      src: scooter,
      alt: "Neon scooter 50cm3- rental scooter in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 1,
    title: "Scooter 50cm3",
    spec: "Fuel Eurosuper 95",
    image: {
      src: scooter2,
      alt: "Neon scooter 50cm3- rental scooter in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 1,
    title: "Scooter 110cm3",
    spec: "Fuel Eurosuper 95",
    image: {
      src: scooter3,
      alt: "Honda scooter 50cm3- rental scooter in Vela Luka Korčula",
    },
    stars: 3,
  },
  {
    id: 1,
    title: "Nmax scooter 110cm3",
    spec: "Fuel Eurosuper 95",
    image: {
      src: scooter4,
      alt: "Nmax scooter 50cm3- rental scooter in Vela Luka Korčula",
    },
    stars: 3,
  },
];

export default scooters;
