import Navbar from "../components/rents/Navbar";
import Faq from "../components/rents/Faq";
import Footer from "../components/Footer";
import Scooter from "../components/rents/Scooter";
import Contact from "../components/rents/Contact";
import PriceBike from "../components/rents/PriceBike";
const faqs = [
  {
    question: "Na koliko vremena mogu iznajmiti skuter",
    answer: `
    <li>4h</li>
    <li>6h</li>
    <li>12h</li>
    <li>24h</li>
    <li>3days</li>
    <li>5days</li>
    <li>7days</li>
    <li>14days</li>
    `,
  },
  {
    question: "Može li se rezervacija promijeniti ili otkazati?",
    answer:
      "Rezervaciju je potrebno izvršiti pismenim putem (e-mailom ili GSM-om). Promjene predstavljaju promjenu imena iznajmljivača ili datuma početka i/ili završetka usluge. Ako najmoprimac otkaže rezervaciju do 14 dana prije dogovorenog vremena preuzimanja, naplaćuje se naknada za otkazivanje u iznosu od 15,00 €. Ako najmoprimac otkaže rezervaciju unutar 14 dana prije dogovorenog vremena preuzimanja, ne preuzme unajmljeno vozilo ili otkaže rezervaciju nakon dogovorenog vremena preuzimanja, naplaćuje se naknada za otkazivanje od 20% ukupnog iznosa rezervacije.",
  },
  {
    question: "Koliko godina mora imati vozač?",
    answer: "Vozač skutera mora imati najmanje 24 godine.",
  },
  {
    question: "Je li gorivo uključeno u cijenu?",
    answer:
      "Gorivo nije uključeno u cijenu najma vozila, a prilikom povratka vozilo je potrebno vratiti s istom količinom goriva u kojoj je i preuzeto.",
  },
  {
    question: "Što je potrebno za najam skutera?",
    answer:
      "Vozačka dozvola, prije preuzimanja skutera kod najmoprimca se provjerava da li zna upravljati njime (vozačka dozvola ne znači da osoba zna upravljati skuterom).",
  },
];
const option = [
  {
    option: "Scooter 50cm3",
    value: "Scooter 50cm3",
  },
];

const HrScooterRental = () => {
  return (
    <>
      <Navbar />
      <Scooter />
      <PriceBike />
      <div className="container mx-auto px-4 py-6 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default HrScooterRental;
