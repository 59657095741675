import Footer from "../components/Footer";
import Bicycle from "../components/rents/Bicycle";
import Faq from "../components/rents/Faq";
import Navbar from "../components/rents/Navbar";
import Contact from "../components/rents/Contact";
import PriceBic from "../components/rents/PriceBic";

const faqs = [
  {
    question: "Can reservation be changed or cancelled?",
    answer:
      "Cancellation of bike hire is subject to a cancellation charge which varies depending on the time of notice given to us prior to the start date of the hire. Cancellation within 5 days of the date you need to rent the bike, you will be charged the full cost of the rental and sea money will not be returned. Cancellations between the 2 weeks you wish to cancel, you will be charged an 80% cancellation fee of your refund. Cancellations between 2 and 4 weeks that you wish to cancel, your refund will be charged 50% of the cancellation fee. Cancellations made more than a month (30 days) before the date of the reserved bikes will not be charged. Cancellation must be received in writing or via email or whatsapp.",
  },
  {
    question: "What if our bicycle is stolen during the rental period?",
    answer:
      "If someone steals your bike or you lose bike accessories, such as locks or repair kits, you are responsible for the loss, according to the terms of our Rental Agreement. If your bike is stolen, you must notify us immediately, and if you have insurance at home that can pay for your stolen bike, you will need to request a police report at your local police station. For missing bicycle equipment, we charge its retail value, check the price list.",
  },
  {
    question: "What I need to rent a bicycle?",
    answer:
      "A valid document (ID, driver’s license, or passport) is required to fill out the Rental Agreement.",
  },
  {
    question: "Returning the bike?",
    answer:
      "The rule is to return all your equipment a minimum of 15 minutes before closing time in order to allow time to check out. In the case of bad weather, you can return the bike and reschedule the rental (please call). All late returns can be taken into account within the minimum delays, but over that are subject to overtime charges.",
  },
  {
    question: "What should I do if I have a flat tire?",
    answer:
      "We are very sorry when this happens. This can happen from time to time and it is something beyond our control. We offer a FREE tire repair kit and a small air pump for rentals longer than 3 days, otherwise you can rent it for €4 per day, and if you know how to fix a flat tire, you can keep pedaling. If you don't know how to deal with the situation - contact us, we will come as soon as possible and fix the problem; you will be billed for parts, repair and assistance. Please do not ride on a flat tire - it damages the wheel. If you drive on a flat tire, you may have to pay.",
  },
  {
    question:
      "What is the renter's responsibility regarding bike maintenance and repair during the rental period, and what are the consequences for lost, stolen, or damaged equipment?",
    answer:
      "Although all our bikes are professionally serviced before dispatch, bicycles may need tuning or maintenance during the course of the rental period; such maintenance will be carried out at the renter's expense. Customer agrees he/she will return the equipment in the same good condition as when received, ordinary wear and tear accepted, and to repair and replace lost or stolen, damaged or broken bicycles or parts or to reimburse Cycling-Rentals for said equipment. Therefore regardless of the party at fault, customer understands and agrees to be responsible for the theft or damage to said equipment.",
  },
  {
    question:
      "What occurrences during bike rental is Cycling Rentals not responsible for?",
    answer:
      "Cycling Rentals is not responsible for the following occurrences during bike rental: gear tune ups / punctures / broken spokes / broken chains / broken derailleurs / broken drop-outs / wheel rim damage / torn saddles / stripped threads on pedal crank / damage beyond the control of Cycling Rentals and resultant of rider use or misuse.",
  },
];
const option = [
  {
    option: "Mountain Bike Cube",
    value: "Mountain Bike Cube",
  },
];
const BicycleRental = () => {
  return (
    <>
      <Navbar />
      <Bicycle />
      <PriceBic />
      <div className="container mx-auto px-4 py-10 grid md:grid-cols-1 lg:grid-cols-2 gap-5">
        <Faq faqs={faqs} />
        <Contact option={option} />
      </div>
      <Footer />
    </>
  );
};

export default BicycleRental;
